import { setErrorStatus } from "../redux/status/actions";
import { subcategoryCode } from "../utils/constants";
import { throwError } from "./error";
import { api } from "./utils";

export async function checkAccountAvailability(phoneNumber) {
  const response = await fetch(
    `${
      api.baseUrl
    }/nintoProfileManagement/accountExists?phoneNumber=${encodeURIComponent(
      phoneNumber
    )}&type=doctor`,
    {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }
  );
  const result = await response.json();

  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function acceptClinicRequest(requestId, accessToken) {
  const response = await fetch(`${api.baseUrl}/acceptConnectionRequest`, {
    method: "post",
    body: JSON.stringify({
      requestId: `${requestId}`
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-token": `Bearer ${accessToken}`
    }
  });
  const result = await response.json();
  if (result.success === "true" || result.success === true) {
    return true;
  } else {
    throwError("custom", "Invalid Request-ID, Please contact the Clinic");
  }
}

export async function checkHPRIdExists(HprId) {
  const response = await fetch(
    `${api.baseUrl}/abdmRegistration/doctor/searchUserByHprId`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        hprId: `${HprId}`
      })
    }
  );
  const result = await response.json();

  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function findPatientWithHealthIdOrPhoneNumber(
  data,
  clinicId,
  accessToken
) {
  const response = await fetch(
    `${api.baseUrl}/nintoProfileManagement/findPatient`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        ...data,
        clinicId
      })
    }
  );
  const result = await response.json();

  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function sendAadhaarOtpForHPRRegistration(
  aadhaarNumber,
  nintoAccessToken
) {
  const response = await fetch(
    `${api.baseUrl}/abdmProfileAuthentication/doctor/register/aadhaar/generateOtp`,
    {
      method: "post",
      body: JSON.stringify({
        aadhaar: `${aadhaarNumber}`
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${nintoAccessToken}`
      }
    }
  );
  const result = await response.json();

  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function verifyAadhaarOtpForHPRRegistration(
  otp,
  transactionId,
  nintoAccessToken
) {
  const response = await fetch(
    `${api.baseUrl}/abdmProfileAuthentication/doctor/register/aadhaar/verifyOtp`,
    {
      method: "post",
      body: JSON.stringify({
        transactionId: `${transactionId}`,
        otp: otp
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-abdm-token": `Bearer ${nintoAccessToken}`
      }
    }
  );
  const result = await response.json();

  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function sendMobileOtpForHPRRegistration(
  transactionId,
  mobileNumber,
  nintoAccessToken
) {
  const response = await fetch(
    `${api.baseUrl}/abdmProfileAuthentication/doctor/register/mobile/authentication`,
    {
      method: "post",
      body: JSON.stringify({
        transactionId: transactionId,
        mobileNumber: mobileNumber
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-abdm-token": `Bearer ${nintoAccessToken}`
      }
    }
  );
  const result = await response.json();

  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function verifyMobileOtpForHPRRegistration(
  otp,
  transactionId,
  nintoAccessToken
) {
  const response = await fetch(
    `${api.baseUrl}/abdmProfileAuthentication/doctor/register/mobileAuthentication/verifyOtp`,
    {
      method: "post",
      body: JSON.stringify({
        transactionId: `${transactionId}`,
        otp: otp
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-abdm-token": `Bearer ${nintoAccessToken}`
      }
    }
  );
  const result = await response.json();

  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function createHprId(data, transactionId, nintoAccessToken) {
  const response = await fetch(
    `${api.baseUrl}/abdmProfileAuthentication/doctor/register/aadhaar/createHprId`,
    {
      method: "post",
      body: JSON.stringify({
        hpCategoryCode: "1",
        hprId: data.hprId,
        email: data.email,
        password: data.password,
        hpSubCategoryCode: subcategoryCode(data.subcategory),
        transactionId: transactionId
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-abdm-token": `Bearer ${nintoAccessToken}`
      }
    }
  );
  const result = await response.json();

  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function sendOtpForHPRLogin(hprId, nintoAccessToken) {
  const response = await fetch(
    `${api.baseUrl}/abdmProfileAuthentication/doctor/login/generateOtp`,
    {
      method: "post",
      body: JSON.stringify({
        hprId: `${hprId}`
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-abdm-token": `Bearer ${nintoAccessToken}`
      }
    }
  );
  const result = await response.json();

  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function verifyOtpForHPRLogin(
  otp,
  transactionId,
  nintoAccessToken
) {
  const response = await fetch(
    `${api.baseUrl}/abdmProfileAuthentication/doctor/login/verifyOtp`,
    {
      method: "post",
      body: JSON.stringify({
        otp: `${otp}`,
        transactionId: `${transactionId}`
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-abdm-token": `Bearer ${nintoAccessToken}`
      }
    }
  );
  const result = await response.json();

  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function sendSubscriptionRequestToPatient(accessToken, data) {
  const response = await fetch(
    `${api.baseUrl}/consentManager/subscription/init`,
    {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      }
    }
  );
  const result = await response.json();

  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function sendOTPForNintoPatientConnection(
  accessToken,
  patientId,
  recipientType,
  recipientPhoneNumber,
  clinicUid
) {
  const response = await fetch(`${api.baseUrl}/nintoConnectPatient/sendOtp`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "x-auth-token": `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      patientId,
      recipientType,
      recipientPhoneNumber,
      clinicUid
    })
  });
  const result = await response.json();

  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function confirmOTPForNintoPatientConnection(
  accessToken,
  otp,
  userType,
  userPhoneNumber,
  otpType,
  requestDocumentId
) {
  const response = await fetch(`${api.baseUrl}/nintoConnectPatient/verifyOtp`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-token": `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      otp,
      userType,
      userPhoneNumber,
      otpType,
      requestDocumentId
    })
  });
  const result = await response.json();

  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function sendConsentRequest(accessToken, data) {
  const response = await fetch(
    `${api.baseUrl}/consentManager/consentRequest/init`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify(data)
    }
  );
  const result = await response.json();

  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function retryDataTransfer(token, bodyData) {
  const response = await fetch(
    `${api.baseUrl}/consentManager/healthInformation/request`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${token}`
      },
      body: JSON.stringify({
        clinicId: bodyData.clinicId,
        consentRequestId: bodyData.consentRequestId,
        patientId: bodyData.patientId,
        ...(bodyData.consentId ? { consentId: bodyData.consentId } : {}),
        ...(bodyData.subscriptionId
          ? { subscriptionId: bodyData.subscriptionId }
          : {})
      })
    }
  );
  const result = await response.json();
  return result;
}

import React, { useState } from "react";
import { connect } from "react-redux";
import { isValidObject } from "../../front-end-global-components/services/validators";
import {
  updateNotifications,
  setNotificationRead
} from "../../redux/notifications/actions";
import closeIcon from "../../assets/icons/close.svg";
import { getFCMToken } from "../../services/cloudMessaging";
import { setNotificationPermission } from "../../redux/app/actions";
import NoNotificationIcon from "../../front-end-global-components/assets/NoNotifications.svg";

function NotificationTimeLine(props) {
  const [permissionStatus, setPermissionStatus] = useState(
    window.Notification.permission
  );

  const dateAndTimeConverter = (timeStamp) => {
    if (!timeStamp) return;
    let dateObject = new Date(timeStamp);
    const date =
      dateObject.toLocaleString("en-IN", { day: "2-digit" }) +
      " " +
      dateObject.toLocaleString("en-IN", { month: "short" }) +
      " " +
      dateObject.toLocaleString("en-IN", { year: "numeric" }) +
      " ," +
      dateObject.toLocaleString("en-IN", { timeStyle: "short" });
    return date;
  };

  const onNotificationScroll = (event) => {
    const bottom =
      parseInt(event.target.scrollHeight - event.target.scrollTop) ===
      event.target.clientHeight;
    if (bottom) {
      if (!Object.values(props.notifications.pagination).includes("end")) {
        props.updateNotifications();
      }
    }
  };

  const handlePermission = async () => {
    await getFCMToken();
    setPermissionStatus(window.Notification.permission);
  };

  return (
    <>
      <div className="inherit-parent-width remaining-height-actions-header display-flex flex-direction-column ">
        {isValidObject(props.notifications.data) ? (
          <div className="inherit-parent-width display-flex flex-justify-content-space-between flex-align-items-center padding-medium font-weight-normal font-family-gilroy-regular font-size-small ">
            <p>NOTIFICATIONS</p>
            {Object.values(props.notifications.data).some(
              (data) => data.read === false
            ) && (
              <span
                onClick={() => {
                  props.setNotificationRead(null, "all");
                }}
                data-cy="notifications-timeline-marl-all-as-read"
                className="border-2px-lite-grey border-radius-default padding-smaller text-align-center font-family-gilroy-regular font-size-smaller"
              >
                MARK ALL AS READ
              </span>
            )}
          </div>
        ) : (
          <div className=" text-align-center font-size-small padding-top-medium">
            {" "}
            NOTIFICATIONS{" "}
          </div>
        )}
        <section
          data-cy="notifications-timeline"
          className="padding-horizontal-medium padding-top-smaller overflow-y-auto flex-basis-1 inherit-parent-height "
          onScroll={onNotificationScroll}
        >
          {isValidObject(props.notifications.data) ? (
            <>
              {Object.values(props.notifications.data).map(
                (notification, index) => {
                  return (
                    <div
                      key={index}
                      className="border-bottom cursor-pointer background-white padding-vertical-medium flex-justify-content-space-between flex-align-items-center"
                      onClick={(event) => {
                        event.stopPropagation();
                        if (notification.read === false) {
                          props.setNotificationRead(notification);
                        } else {
                          props.navigate(`/${notification.url}`);
                        }
                      }}
                    >
                      <div>
                        <div
                          className={` ${
                            notification.read
                              ? "font-color-tertiary "
                              : "font-color-secondary "
                          } font-family-gilroy-regular font-size-medium padding-bottom-default`}
                        >
                          {notification.title}
                        </div>
                        <div
                          className={` ${
                            notification.read
                              ? "font-color-tertiary "
                              : "font-color-secondary "
                          } font-family-gilroy-regular font-size-medium padding-bottom-default`}
                        >
                          {notification.description}
                        </div>
                        <div className="font-color-tertiary font-family-gilroy-regular font-size-smaller">
                          {dateAndTimeConverter(notification.timestamp)}
                        </div>
                      </div>
                      {!notification.read && (
                        <div data-cy="notification-unreaded-indicator">
                          <div className=" margin-default background-color-primary-green border-radius-100-percentage padding-small " />
                        </div>
                      )}
                    </div>
                  );
                }
              )}
              {props.notifications.paginationLoading === true && (
                <li className="flex-justify-content-center padding-vertical-small margin-bottom-larger inherit-parent-width">
                  <div className="display-flex-direction-row flex-justify-content-center flex-align-items-center inherit-parent-width">
                    <p>Loading</p> <div className="padding-horizontal-medium" />
                    <div className={`dot-flashing`} />
                    <div className="padding-horizontal-medium" />
                  </div>
                </li>
              )}
              {props.notifications.pagination &&
                Object.values(props.notifications.pagination).includes(
                  "end"
                ) && (
                  <div className="text-align-center padding-top-large padding-bottom-large">
                    No further notification available
                  </div>
                )}
            </>
          ) : (
            <div className=" inherit-parent-height overflow-y-auto flex-place-children-page-center padding-large">
              <img src={NoNotificationIcon} alt="no notifications" />
              <div className="padding-top-large flex-align-items-center text-align-center font-color-secondary">
                No notifications
              </div>
              <div className="padding-top-default flex-align-items-center text-align-center font-color-tertiary">
                You have no notification received yet
              </div>
            </div>
          )}
        </section>
        {permissionStatus === "default" && props.app.notification === null && (
          <EnablePushNotification
            onClick={() => {
              handlePermission();
            }}
            onCloseClick={() => {
              props.setNotificationPermission(false);
            }}
          />
        )}
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    app: state.app,
    notifications: state.notifications
  };
};

const mapDispatchToProps = function () {
  return {
    setNotificationPermission: (status) => setNotificationPermission(status),
    setNotificationRead: (notification, type) =>
      setNotificationRead(notification, type),
    updateNotifications: (paginationNumber) =>
      updateNotifications(paginationNumber)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationTimeLine);

const EnablePushNotification = (props) => {
  return (
    <div className="inherit-parent-width display-flex position-absolute bottom-0 flex-justify-content-space-between padding-large text-overflow-ellipsis">
      <div className="display-flex flex-direction-column text-overflow-ellipsis">
        <div className="font-family-gilroy-medium font-size-medium">
          Enable Push Notification
        </div>
        <div className="padding-top-default font-size-small ">
          Get notified for any updates
        </div>
      </div>
      <div className="display-flex flex-center-children-vertically padding-left-default">
        <div
          onClick={props.onClick}
          className=" background-primary padding-left-default padding-right-default padding-top-smaller padding-bottom-smaller height-fit-content width-fit-content font-color-white font-size-small border-radius-small"
        >
          Accept
        </div>
        <div
          onClick={props.onCloseClick}
          className="display-flex flex-center-children-vertically padding-left-large"
        >
          <img
            src={closeIcon}
            alt="close-icon"
            width={12}
            height={12}
            className=""
          />
        </div>
      </div>
    </div>
  );
};

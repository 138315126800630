import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Header from "../../../front-end-global-components/components/Header/Header";
import Registration from "../../../components/profile/registration/Registration";
import IdentityProof from "../../../components/profile/identityProof/IdentityProof";
import ProfileCompleteSuccess from "../../../components/profile/profileCompleteSuccess/ProfileCompleteSuccess";

import { isValidArray, isValidObject } from "../../../services/validators";
import { validProfileObject } from "../../../services/utils";
import { isValidString } from "../../../front-end-global-components/services/validators";
import CompleteProfile from "../../../components/profile/CompleteProfile/CompleteProfile";
import { editProfileRequest } from "../../../redux/profile/actions";

function ProfileCompletion(props) {
  const [profileCompletionLevelIndex, setProfileCompletionLevelIndex] =
    useState(0);

  const [showSuspense, setShowSuspense] = useState(true);

  useEffect(() => {
    // initial check to render component Level
    let _completionLevelIndex = 0;
    if (validProfileObject(props.profile.data)) {
      const docId = Object.keys(props.profile.data)[0];
      const dataDoc = props.profile.data[docId];

      if (
        isValidObject(dataDoc) &&
        !(
          isValidString(dataDoc.email) &&
          typeof dataDoc.dateOfBirth === "number" &&
          isValidString(dataDoc.public.city) &&
          isValidObject(dataDoc.public.experience) &&
          isValidString(dataDoc.public.gender) &&
          isValidArray(dataDoc.public.speciality)
        )
      ) {
        _completionLevelIndex = 0;
      } else if (
        dataDoc &&
        (!dataDoc.registrationDetails ||
          !isValidArray(dataDoc.public.education))
      ) {
        // No Data
        _completionLevelIndex = 1;
      } else if (dataDoc && !dataDoc.aadhar && !dataDoc.proof) {
        _completionLevelIndex = 2;
      } else if (
        isValidObject(dataDoc) &&
        isValidObject(dataDoc.registrationDetails) &&
        isValidObject(dataDoc.public) &&
        isValidArray(dataDoc.public.education) &&
        isValidObject(dataDoc.aadhar) &&
        typeof dataDoc.proof === "string"
      ) {
        _completionLevelIndex = 3;
      }
      setProfileCompletionLevelIndex(_completionLevelIndex);
    }

    return () => {};
    // eslint-disable-next-line
  }, [JSON.stringify(props.profile.data)]);

  const onContinue = () => {
    setProfileCompletionLevelIndex((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (
      props.profile.loading === false &&
      props.profile?.data &&
      validProfileObject(props.profile.data)
    ) {
      setShowSuspense(false);
    } else {
      setShowSuspense(true);
    }
  }, [props.profile.loading, props.profile.data]);

  return (
    <div className="inherit-parent-height">
      {profileCompletionLevelIndex !== 3 ? (
        <Header
          backButtonOnClick={() => {
            if (profileCompletionLevelIndex > 0) {
              setProfileCompletionLevelIndex((prevState) => prevState - 1);
            } else {
              if (validProfileObject(props.profile.data)) {
                props.navigate("/");
              }
            }
          }}
          title={"Complete your profile now"}
          hideSupport={true}
          maxStepperCount={3}
          currentStepperValue={profileCompletionLevelIndex}
          supportIconOnClick={() => {
            props.navigate("/queries");
          }}
          incident={props.app.featureFlags?.incident?.clinics}
          incidentOnClick={() => {
            props.navigate("/incidents");
          }}
        />
      ) : /* <p>{`${profileCompletionLevelIndex}/3`}</p>
				</Header> */
      null}
      {showSuspense ? (
        <ProfileCompletionSuspense />
      ) : (
        <>
          {profileCompletionLevelIndex === 0 && (
            <CompleteProfile
              profile={props.profile}
              navigate={props.navigate}
              onContinue={onContinue}
              editProfileRequest={editProfileRequest}
            />
          )}
          {profileCompletionLevelIndex === 1 && (
            <Registration
              auth={props.auth}
              profile={props.profile}
              onContinue={onContinue}
              navigate={props.navigate}
            />
          )}
          {profileCompletionLevelIndex === 2 && (
            <IdentityProof
              navigate={props.navigate}
              profile={props.profile}
              onContinue={onContinue}
            />
          )}
          {profileCompletionLevelIndex === 3 && (
            <ProfileCompleteSuccess
              navigate={props.navigate}
              onContinue={onContinue}
            />
          )}
        </>
      )}
      {/* <ProfileCurrentComponent
          profileCompletionLevelIndex={profileCompletionLevelIndex}
          onContinue={onContinue}
          navigate={props.navigate}
        /> */}
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    app: state.app
  };
};

const mapDispatchToProps = function () {
  return {
    editProfileRequest: (data) => editProfileRequest(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCompletion);

function ProfileCompletionSuspense() {
  return (
    <article className="screen-loader-wrapper flex-center-children-horizontally">
      <main className="padding-medium max-width-588px inherit-parent-width ">
        <div className="shimmer padding-medium max-width-588px inherit-parent-width border-radius-default margin-vertical-larger" />
        <div className="shimmer padding-medium max-width-588px inherit-parent-width border-radius-default margin-vertical-larger">
          <div className="padding-default" />
        </div>
        <div className="shimmer padding-medium max-width-588px inherit-parent-width border-radius-default margin-vertical-larger">
          <div className="padding-default" />
        </div>

        <div className="shimmer padding-medium max-width-588px inherit-parent-width border-radius-default margin-vertical-larger">
          <div className="padding-default" />
        </div>

        <section className="margin-vertical-larger display-flex-direction-row flex-justify-content-space-between">
          <div className="shimmer inherit-parent-width padding-medium border-radius-default margin-right-large">
            <div className="padding-default" />
          </div>
          <div className="shimmer inherit-parent-width padding-medium border-radius-default margin-left-large">
            <div className="padding-default" />
          </div>
        </section>
      </main>
    </article>
  );
}

import React, { useState, useEffect, useRef } from "react";

import InputBox from "../../../front-end-global-components/components/InputBox/InputBox";
import Button from "../../../front-end-global-components/components/Button/Button";
import { validProfileObject } from "../../../services/utils";
import { isValidObject } from "../../../services/validators";
import editIcon from "../../../assets/icons/Edit.svg";
import {
  isValidArray,
  isValidString
} from "../../../front-end-global-components/services/validators";
import "./Registration.css";
import { editProfileRequest } from "../../../redux/profile/actions";

export default function Registration(props) {
  const [showBottomModal, setShowBottomModal] = useState(false);
  const educationCompRef = useRef(null);
  const educationListCompRef = useRef(null);

  const [formData, setFormData] = useState({
    registrationNumber: null,
    registrationCouncil: null,
    registrationYear: null
  });

  const educationDetailsToBeEditedRef = useRef({
    college: "",
    degree: "",
    yearOfCompletion: ""
  });

  const [educationsList, setEducationsList] = useState([]);

  useEffect(() => {
    if (props.profile.data) {
      const docId = Object.keys(props.profile.data);
      const dataDoc = props.profile.data[docId];

      if (
        dataDoc &&
        dataDoc.registrationDetails &&
        isValidObject(dataDoc.public)
      ) {
        // Has Data
        setFormData({ ...dataDoc.registrationDetails });

        const education = dataDoc.public.education
          ? [...dataDoc.public.education]
          : [];

        if (isValidArray(education)) {
          // Has Data
          setEducationsList([...education]);
        }
      }
    }
    return () => {};
  }, [props]);

  const onFormInputsChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const onSubmitData = (event) => {
    event.preventDefault();
    if (
      validProfileObject(props.profile.data)[0] &&
      typeof formData.registrationCouncil === "string" &&
      formData.registrationCouncil.trim().length !== 0 &&
      typeof formData.registrationNumber === "string" &&
      formData.registrationNumber.trim().length !== 0 &&
      typeof formData.registrationYear === "string" &&
      formData.registrationYear.trim().length !== 0 &&
      formData.registrationYear.length === 4 &&
      isValidArray(educationsList) &&
      !props.profile.loading
    ) {
      const profileRegistrationData =
        props.profile.data?.[props.auth.data.uid]?.registrationDetails;
      const oldEducationList =
        props.profile.data?.[props.auth.data.uid]?.public.education;

      let educationsListWithoutIndexInEducationData = [];
      educationsList.forEach((educationData) => {
        delete educationData["index"];
        educationsListWithoutIndexInEducationData.push(educationData);
      });

      const stringifiedNewEducationList = JSON.stringify(
        educationsListWithoutIndexInEducationData
      );
      const stringifiedOldEducationList = JSON.stringify(oldEducationList);

      if (
        profileRegistrationData &&
        profileRegistrationData.registrationCouncil ===
          formData.registrationCouncil &&
        profileRegistrationData.registrationNumber ===
          formData.registrationNumber &&
        profileRegistrationData.registrationYear ===
          formData.registrationYear &&
        stringifiedNewEducationList === stringifiedOldEducationList
      ) {
        props.onContinue();
      } else {
        // Not same Object so update to DB
        editProfileRequest({
          registrationDetails: { ...formData },
          education: [...educationsListWithoutIndexInEducationData]
        });
      }
    } else {
      let educationsListWithoutIndexInEducationData = [];
      educationsList.forEach((educationData) => {
        delete educationData["index"];
        educationsListWithoutIndexInEducationData.push(educationData);
      });
      // Not same Object so update to DB
      editProfileRequest({
        registrationDetails: { ...formData },
        education: [...educationsListWithoutIndexInEducationData]
      });
    }
  };

  const isSubmitButtonDisabled = () => {
    if (
      isValidArray(educationsList) &&
      typeof formData.registrationCouncil === "string" &&
      formData.registrationCouncil.trim().length !== 0 &&
      typeof formData.registrationNumber === "string" &&
      formData.registrationNumber.trim().length !== 0 &&
      typeof formData.registrationYear === "string" &&
      formData.registrationYear.trim().length !== 0 &&
      formData.registrationYear.length === 4
    ) {
      const regx = /^[0-9\b]+$/;
      if (
        regx.test(formData.registrationNumber) &&
        regx.test(formData.registrationYear)
      ) {
        const userEnteredYear = formData.registrationYear;
        const currentYear = new Date().getFullYear();
        const reducedYear = currentYear - 100;
        if (userEnteredYear >= reducedYear && userEnteredYear <= currentYear) {
          //Valid Date
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const registrationYearValidation = (value) => {
    let result = {};
    if (value) {
      const currentYear = new Date().getFullYear();
      const reducedYear = currentYear - 100;
      if (value.length !== 4) {
        result = {
          status: false,
          message: "Please enter a valid Registration year"
        };
      } else if (value.length === 4) {
        if (parseInt(value) > currentYear) {
          result = {
            status: false,
            message: "Please enter a valid Registration year"
          };
        } else if (
          parseInt(value) >= reducedYear &&
          parseInt(value) <= currentYear
        ) {
          //Valid Date
          result = {
            status: true,
            message: null
          };
        } else {
          result = {
            status: false,
            message: "Please enter a valid Registration year"
          };
        }
      } else {
        result = {
          status: true,
          message: null
        };
      }
    } else {
      result = {
        status: false,
        message: "Please enter a valid Registration year"
      };
    }
    return result;
  };

  const addEducation = (educationDetail) => {
    if (isValidObject(educationDetailsToBeEditedRef.current)) {
      setEducationsList((prevState) => {
        let tempEducationList = [...prevState];
        tempEducationList.splice(
          educationDetailsToBeEditedRef.current.index,
          1
        );
        return [educationDetail, ...tempEducationList];
      });
      educationDetailsToBeEditedRef.current = {};
    } else {
      setEducationsList((prevState) => [...prevState, educationDetail]);
    }
  };

  return (
    <>
      <article className="remaining-body-height overflow-x-scroll flex-start-place-children-vertically ">
        <h3 className="font-weight-normal font-size-large padding-top-larger font-color-secondary font-family-gilroy-regular ">
          REGISTRATION & EDUCATION DETAILS
        </h3>
        {props.profile.loading === true ? (
          <RegistrationSuspense />
        ) : (
          <form
            data-cy="registration-details-form"
            className="inherit-parent-width inherit-parent-height overflow-x-scroll grey-abstract-background-image max-width-500px display-flex flex-direction-column padding-medium"
            onChange={(event) => {
              onFormInputsChange(event);
            }}
          >
            <main className="inherit-parent-width inherit-parent-height flex-basis-1 ">
              <InputBox
                className="inherit-parent-width margin-vertical-large"
                name="registrationNumber"
                label="Registration Number"
                type="number"
                autoComplete="false"
                required
                value={formData.registrationNumber}
              />

              <InputBox
                className="inherit-parent-width margin-vertical-large"
                name="registrationCouncil"
                label="Registration Council"
                type="text"
                autoComplete="false"
                required
                value={formData.registrationCouncil}
              />

              <InputBox
                className="inherit-parent-width margin-vertical-large"
                name="registrationYear"
                label="Registration Year"
                type="number"
                autoComplete="false"
                required
                value={formData.registrationYear}
                validation={registrationYearValidation}
              />
              <div className="inherit-parent-width padding-bottom-smaller">
                <section
                  ref={educationCompRef}
                  className="inherit-parent-width flex-align-items-center flex-justify-content-space-between font-size-medium font-family-gilroy-medium inherit-parent-width"
                >
                  <p className="font-family-gilroy-medium font-size-medium font-color-secondary">
                    EDUCATION
                  </p>
                  <div
                    className=" padding-small font-family-gilroy-medium font-size-smaller  background-transparent margin-left-8px suffix-button-border border-radius-0p7em text-transform-uppercase "
                    onClick={() => setShowBottomModal(true)}
                  >
                    Add New
                  </div>
                </section>
              </div>

              <div
                className="inherit-parent-width border-bottom-1px-solid-grey padding-bottom-default"
                ref={educationListCompRef}
              >
                {isValidArray(educationsList) &&
                  educationsList.map((educationDetail, index) => {
                    return (
                      <section
                        data-cy={`education-tile-${index}`}
                        key={`education-tile-${index}`}
                        className="inherit-parent-width flex-align-items-center flex-justify-content-space-between margin-top-large margin-bottom-small"
                      >
                        <div>
                          <p className="font-family-gilroy-regular font-size-medium font-color-secondary">
                            {educationDetail.college}
                          </p>
                          <p className="font-family-gilroy-regular font-size-small font-color-secondary text-transform-uppercase ">
                            {`${educationDetail.degree}, ${educationDetail.yearOfCompletion}`}
                          </p>
                        </div>
                        <img
                          data-cy={`edit-education-tile-${index}`}
                          onClick={() => {
                            educationDetailsToBeEditedRef.current = {};
                            educationDetailsToBeEditedRef.current = {
                              ...educationDetail,
                              index: index
                            };
                            setShowBottomModal(true);
                          }}
                          src={editIcon}
                          alt="edit"
                        />
                      </section>
                    );
                  })}
              </div>
            </main>

            <footer className="padding-top-larger inherit-parent-width">
              <Button
                loading={props.profile.loading}
                id="submit-btn"
                type="submit"
                text="Continue"
                onClick={onSubmitData}
                disabled={isSubmitButtonDisabled()}
                boxShadow={false}
              />
              <button
                type="button"
                className=" inherit-parent-width margin-top-larger background-color-transparent font-size-small font-color-secondary font-family-gilroy-regular "
                onClick={() => props.navigate("/")}
              >
                Skip
              </button>
            </footer>
          </form>
        )}
      </article>
      <AnimatedBottomModal
        show={showBottomModal}
        onClickClose={() => {
          setShowBottomModal(false);
          educationDetailsToBeEditedRef.current = {};
        }}
        educationCompRef={educationCompRef}
        educationDetails={educationDetailsToBeEditedRef.current}
        profile={props.profile}
        addEducation={addEducation}
        educationListCompRef={educationListCompRef}
      />
    </>
  );
}

function AnimatedBottomModal(props) {
  const [entranceAnimation, setEntranceAnimation] = useState(false);
  const childElementRef = useRef(null);
  const bottomCardRef = useRef(null);
  const [educationDetails, setEducationDetails] = useState({
    college: "",
    degree: "",
    yearOfCompletion: ""
  });

  const closeModal = () => {
    setEntranceAnimation(false);
    setTimeout(() => {
      bottomCardRef.current &&
        bottomCardRef.current.classList.remove("height-70vh");
      bottomCardRef.current &&
        bottomCardRef.current.classList.add("height-50vh");
      props.onClickClose();
      setEducationDetails({
        college: "",
        degree: "",
        yearOfCompletion: ""
      });
    }, 80);
  };

  useEffect(() => {
    if (isValidObject(props.educationDetails) && props.show === true) {
      setEducationDetails({ ...props.educationDetails });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    if (props.show === true) {
      setEntranceAnimation(true);
      const inputElement = document.getElementById("college");
      inputElement && inputElement.focus();
    }
    if (props.closeModal) {
      props.closeModal(closeModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        !(
          (childElementRef.current &&
            childElementRef.current.children[0].contains(event.target)) ||
          (props.educationCompRef.current &&
            props.educationCompRef.current.contains(event.target)) ||
          (props.educationListCompRef.current &&
            props.educationListCompRef.current.contains(event.target))
        )
      ) {
        closeModal();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childElementRef]);

  const yearOfCompletionValidation = (value) => {
    let result = {};
    if (value) {
      const currentYear = new Date().getFullYear();
      const reducedYear = currentYear - 100;
      if (value.length !== 4) {
        result = {
          status: false,
          message: "Please enter a valid Year of Completion"
        };
      } else if (value.length === 4) {
        const currentYear = new Date().getFullYear();
        if (parseInt(value) > currentYear) {
          result = {
            status: false,
            message: "Please enter a valid Year of Completion"
          };
        } else if (
          parseInt(value) >= reducedYear &&
          parseInt(value) <= currentYear
        ) {
          //Valid Date
          result = {
            status: true,
            message: null
          };
        } else {
          result = {
            status: false,
            message: "Please enter a valid Year of Completion"
          };
        }
      } else {
        result = {
          status: true,
          message: null
        };
      }
    } else {
      result = {
        status: false,
        message: "Please enter a valid Year of Completion"
      };
    }
    return result;
  };

  const isAddEducationSubmitButtonDisabled = () => {
    if (
      isValidString(educationDetails.college) &&
      isValidString(educationDetails.degree) &&
      typeof educationDetails.yearOfCompletion === "string" &&
      educationDetails.yearOfCompletion.length === 4
    ) {
      const regex = /^[0-9\b]+$/;
      if (regex.test(educationDetails.yearOfCompletion)) {
        const userEnteredYear = educationDetails.yearOfCompletion;
        const currentYear = new Date().getFullYear();
        const reducedYear = currentYear - 100;
        if (userEnteredYear >= reducedYear && userEnteredYear <= currentYear) {
          //Valid Date
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      {props.show && (
        <article
          className="background-color-black-with-opacity-light inherit-parent-height inherit-parent-width position-fixed z-index-1 top-0 left-0 "
          data-cy="animated-bottom-modal"
        >
          <main
            className={` inherit-parent-height inherit-parent-width display-flex justify-content-flex-end align-items-flex-end  animated-bottom-modal-anime ${
              entranceAnimation ? "animated-bottom-modal-anime-entrance" : ""
            }`}
            ref={childElementRef}
          >
            <div
              ref={bottomCardRef}
              className="padding-top-default padding-left-large padding-right-large inherit-parent-width flex-center-children-vertically flex-direction-column height-50vh background-white"
            >
              <h5
                className="text-transform-uppercase font-weight-normal font-family-gilroy-regular font-color-secondary margin-top-default margin-bottom-default"
                data-cy="modal-title"
              >
                ADD NEW EDUCATION
              </h5>
              <form
                onChange={(event) => {
                  setEducationDetails((prevState) => {
                    return {
                      ...prevState,
                      [event.target.name]: event.target.value
                    };
                  });
                }}
              >
                <InputBox
                  className="inherit-parent-width margin-vertical-large"
                  type="text"
                  name="college"
                  label="COLLEGE"
                  labelClassName="letter-spacing-4-percentage"
                  value={educationDetails.college}
                />
                <section className="inherit-parent-width flex-direction-row flex-justify-content-space-between margin-vertical-large">
                  <InputBox
                    size="half"
                    className=" border-radius-default margin-right-default"
                    type="text"
                    name="degree"
                    label="DEGREE"
                    labelClassName="letter-spacing-4-percentage"
                    value={educationDetails.degree}
                  />
                  <InputBox
                    size="half"
                    className=" border-radius-default margin-left-default"
                    name="yearOfCompletion"
                    label="YEAR OF COMPLETION"
                    labelClassName="letter-spacing-4-percentage"
                    type="number"
                    required
                    value={educationDetails.yearOfCompletion}
                    validation={yearOfCompletionValidation}
                  />
                </section>
                <section className="margin-top-larger flex-justify-content-space-between">
                  <Button
                    loading={props.profile.loading}
                    data-cy="education-detail-submit-btn"
                    type="submit"
                    text="Add"
                    onClick={(event) => {
                      event.preventDefault();
                      props.addEducation(educationDetails);
                      closeModal();
                    }}
                    disabled={isAddEducationSubmitButtonDisabled()}
                    boxShadow={false}
                  />
                  <Button
                    className="margin-left-medium"
                    data-cy="education-detail-cancel-btn"
                    text="Cancel"
                    variant="secondary"
                    onClick={(_) => {
                      closeModal();
                    }}
                    disabled={false}
                    boxShadow={false}
                  />
                </section>
              </form>
            </div>
          </main>
        </article>
      )}
    </>
  );
}

function RegistrationSuspense() {
  return (
    <article className="screen-loader-wrapper flex-center-children-horizontally">
      <main className="padding-medium max-width-588px inherit-parent-width ">
        <div className="shimmer padding-medium max-width-588px inherit-parent-width border-radius-default margin-vertical-larger" />
        <div className="shimmer padding-medium max-width-588px inherit-parent-width border-radius-default margin-vertical-larger">
          <div className="padding-default" />
        </div>
        <div className="shimmer padding-medium max-width-588px inherit-parent-width border-radius-default margin-vertical-larger">
          <div className="padding-default" />
        </div>

        <div className="shimmer padding-medium max-width-588px inherit-parent-width border-radius-default margin-vertical-larger">
          <div className="padding-default" />
        </div>

        <section className="margin-vertical-larger display-flex-direction-row flex-justify-content-space-between">
          <div className="shimmer inherit-parent-width padding-medium border-radius-default margin-right-large">
            <div className="padding-default" />
          </div>
          <div className="shimmer inherit-parent-width padding-medium border-radius-default margin-left-large">
            <div className="padding-default" />
          </div>
        </section>
      </main>
    </article>
  );
}

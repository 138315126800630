import React, { useEffect, useState } from "react";
import "./Abdm.css";

import logoutLogo from "../../assets/icons/Logout.svg";
import nextIconGreen from "../../assets/icons/nextIconGreen.svg";
import Header from "../../front-end-global-components/components/Header/Header";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import Button from "../../front-end-global-components/components/Button/Button";
import { connect } from "react-redux";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import NativeSelect from "../../front-end-global-components/components/NativeSelect/NativeSelect";
import { validation } from "../../services/validators";
import {
  stateCodeWithDistricts,
  stateOptionsWithCode
} from "../../utils/constants";
import {
  clearABDMAuthCredentials,
  createHPRId,
  logoutHPR,
  resetHPRRegistrationStep,
  sendAadhaarOtpForHPRAuthentication,
  sendMobileOtpForHPRAuthentication,
  verifyAadhaarOtpHPRAuthentication,
  verifyMobileOtpHPRAuthentication
} from "../../redux/abdm/actions";
import { checkHPRIdExists } from "../../services/api";

function AbdmAuthentication(props) {
  const [authMode, setAuthMode] = useState(null);
  const [createNewHealthId, setCreateNewHealthId] = useState(false);
  const [healthAuthentication, setHealthAuthentication] = useState({
    authType: "aadhaarNumber",
    otp: "",
    hprId: "",
    aadhaarNumber: "",
    DriverLicense: "",
    phoneNumber: "",
    inputName: "aadhaarNumber",
    inputType: "tel",
    inputLabel: "Aadhaar number"
  });
  const [formData, setFormData] = useState({
    subcategory: "",
    hprId: "",
    password: "",
    confirmPassword: "",
    email: "",
    stateCode: "",
    districtCode: ""
  });

  const headerTitle = (authMode) => {
    switch (authMode) {
      case "login":
        return "HPR login";
      case "register":
        return "Create HPR";
      default:
        return "Healthcare Professionals Registry";
    }
  };

  const createButtonDisableHandler = () => {
    if (
      formData.subcategory &&
      formData.hprId &&
      formData.password &&
      formData.confirmPassword &&
      formData.email &&
      validation("password", formData.password).status &&
      validation("email", formData.email).status &&
      formData.password === formData.confirmPassword
    ) {
      return false;
    } else {
      return true;
    }
  };
  const HealthDataAuthentication = [
    {
      type: "aadhaarNumber",
      inputType: "tel",
      inputLabel: "Aadhaar number",
      inputName: "aadhaarNumber",
      allowedFor: ["login", "register"],
      text: (method) => {
        return method === "login"
          ? "Use Aadhaar instead"
          : "Use Aadhaar instead";
      }
    },
    {
      type: "DriverLicense",
      inputType: "text",
      inputLabel: "Driver’s license",
      allowedFor: ["login", "register"],
      inputName: "DriverLicense",
      text: (method) => {
        return method === "login"
          ? "Use Driver’s license"
          : "Use Driver’s license";
      }
    }
  ];

  const resetAuthState = () => {
    if (
      !!props.abdm.auth.otp.requestMethod ||
      !!props.abdm.auth.transactionId
    ) {
      props.clearABDMAuthCredentials();
      props.resetHPRRegistrationStep();
    }
    setAuthMode(null);
    setHealthAuthentication({
      authType: "aadhaarNumber",
      otp: "",
      aadhaarNumber: "",
      DriverLicense: "",
      phoneNumber: "",
      inputName: "aadhaarNumber",
      inputType: "tel",
      inputLabel: "Aadhaar number"
    });
  };

  const headerGoBackOnClickHandler = () => {
    switch (authMode) {
      case "login":
      case "register":
        if (createNewHealthId === true) {
          setCreateNewHealthId(false);
        } else resetAuthState();
        break;
      case "loggedIn":
        return props.navigate(-1);
      default:
        props.navigate(-1);
        break;
    }
  };

  useEffect(() => {
    resetAuthState();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.abdm.auth.data.accessToken) {
      setAuthMode("loggedIn");
    }
  }, [props.abdm.auth.data.accessToken]);

  return (
    <>
      <Header
        title={headerTitle(authMode)}
        backButtonOnClick={() => {
          props.navigate(-1);
          headerGoBackOnClickHandler(authMode);
        }}
        incident={props.app.featureFlags?.incident?.clinics}
        incidentOnClick={() => {
          props.navigate("/incidents");
        }}
      />
      {authMode === null && (
        <section className=" display-flex flex-justify-content-center padding-top-larger">
          <>
            <ABDMButton
              name="Login"
              detail="Existing HPR"
              onClick={() => {
                setAuthMode("login");
              }}
            />
            <ABDMButton
              name="Create"
              detail="New HPR"
              onClick={() => {
                setAuthMode("register");
                setCreateNewHealthId(true);
              }}
            />
          </>
        </section>
      )}
      {authMode === "login" && (
        <HPRLoginComponent
          authMode={authMode}
          hprIdSuffixButtonDisabled={
            healthAuthentication.hprId?.length > 3 ? false : true
          }
          setAuthMode={(data) => setAuthMode(data)}
          HealthDataAuthentication={HealthDataAuthentication}
          healthAuthentication={healthAuthentication}
          abdm={props.abdm}
          onOTPButtonClick={() => {
            if (props.abdm.loading === false) {
              props.sendAadhaarOtpForHPRAuthentication(
                authMode,
                "hprId",
                healthAuthentication.hprId
              );
            }
          }}
          onLoginFormSubmit={(event) => {
            event.preventDefault();
            if (props.abdm.loading === false) {
              props.verifyAadhaarOtpHPRAuthentication(
                authMode,
                "hprId",
                healthAuthentication.otp
              );
            }
          }}
          setHealthAuthentication={(data) => {
            setHealthAuthentication(data);
          }}
        />
      )}
      {authMode === "register" && (
        <HPRRegisterComponent
          registrationStep={props.abdm.registrationStep}
          onChange={(event) => {
            setHealthAuthentication({
              ...healthAuthentication,
              [event.target.name]: event.target.value
            });
          }}
          onAadhaarButtonClick={(event) => {
            event.preventDefault();
            if (props.abdm.loading === false) {
              props.sendAadhaarOtpForHPRAuthentication(
                authMode,
                healthAuthentication.authType,
                healthAuthentication[healthAuthentication.authType]
              );
            }
          }}
          onFirstStepFormSubmit={(event) => {
            event.preventDefault();
            props.verifyAadhaarOtpHPRAuthentication(
              authMode,
              healthAuthentication[healthAuthentication.authType],
              healthAuthentication.otp
            );
          }}
          onSecondStepFormSubmit={(event) => {
            event.preventDefault();
            if (props.abdm.loading === false) {
              props.sendMobileOtpForHPRAuthentication(
                healthAuthentication.phoneNumber
              );
            }
          }}
          onVerifyMobileOTPButtonOnClick={(event) => {
            event.preventDefault();
            if (props.abdm.loading === false) {
              props.verifyMobileOtpHPRAuthentication(
                healthAuthentication.otp,
                authMode
              );
            }
          }}
          onThirdStepFormSubmit={(event) => {
            event.preventDefault();
            if (props.abdm.loading === false) {
              props.createHPRId(formData);
            }
          }}
          HPRIdOutOfFocus={(event) => {
            if (event.target.value) {
              props.checkHPRIdExists(event.target.value);
            }
          }}
          fromOnChange={(event) => {
            setFormData({
              ...formData,
              [event.target.name]: event.target.value
            });
          }}
          createButtonDisabled={createButtonDisableHandler()}
          formData={formData}
          authMode={authMode}
          setAuthMode={(data) => setAuthMode(data)}
          abdm={props.abdm}
          HealthDataAuthentication={HealthDataAuthentication}
          healthAuthentication={healthAuthentication}
          setHealthAuthentication={(data) => {
            setHealthAuthentication(data);
          }}
        />
      )}
      {authMode === "loggedIn" && (
        <section className="remaining-body-height max-width-588px margin-horizontal-auto padding-right-large padding-left-large overflow-y-auto inherit-parent-width ">
          <div
            className=" flex-direction-row inherit-parent-width flex-justify-content-space-between padding-vertical-large"
            onClick={() => {
              props.logoutHPR();
              setAuthMode("login");
            }}
          >
            <div>
              <div className="font-color-red padding-bottom-small">
                Logout HPR
              </div>
              <div className="font-color-red font-size-medium">
                Logout / use different HPR
              </div>
            </div>
            <div className=" flex-center-children-vertically ">
              <img
                src={logoutLogo}
                className="height-0P8em"
                alt="logout_logo"
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  abdm: state.abdm,
  profile: state.profile,
  app: state.app
});

const mapDispatchToProps = function () {
  return {
    sendAadhaarOtpForHPRAuthentication: (
      requestType,
      requestMethod,
      requestValue
    ) =>
      sendAadhaarOtpForHPRAuthentication(
        requestType,
        requestMethod,
        requestValue
      ),
    checkHPRIdExists: (HPRId) => checkHPRIdExists(HPRId),
    logoutHPR: () => logoutHPR(),
    sendMobileOtpForHPRAuthentication: (phoneNumber) =>
      sendMobileOtpForHPRAuthentication(phoneNumber),
    verifyAadhaarOtpHPRAuthentication: (requestType, requestValue, otp) => {
      verifyAadhaarOtpHPRAuthentication(requestType, requestValue, otp);
    },
    createHPRId: (data) => {
      createHPRId(data);
    },
    clearABDMAuthCredentials: () => {
      clearABDMAuthCredentials();
    },
    resetHPRRegistrationStep: () => {
      resetHPRRegistrationStep();
    },
    verifyMobileOtpHPRAuthentication: (otp, requestType) => {
      verifyMobileOtpHPRAuthentication(otp, requestType);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AbdmAuthentication);

const HPRLoginComponent = (props) => {
  return (
    <section className="remaining-body-height max-width-588px margin-horizontal-auto padding-right-large padding-left-large overflow-y-auto inherit-parent-width ">
      <form
        className="padding-right-large padding-left-large inherit-parent-height  flex-justify-content-space-between flex-direction-column"
        onSubmit={props.onLoginFormSubmit}
      >
        <section>
          <div className=" line-height-140-percentage text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary text-transform-uppercase padding-right-small padding-left-small padding-top-large padding-bottom-larger ">
            Login with Healthcare Professionals Registry.
          </div>
          <InputBox
            className="inherit-parent-width border-radius-default margin-bottom-default"
            name={"hprId"}
            label={"HEALTHCARE PROFESSIONAL ID"}
            type={"text"}
            otpFocus={true}
            autoComplete="off"
            value={props.healthAuthentication.hprId}
            buttonTile={
              !!props.abdm.auth.otp.requestMethod ? "Resend OTP" : "Get OTP"
            }
            suffixButtonDisabled={props.hprIdSuffixButtonDisabled}
            buttonType={
              !!props.abdm.auth.otp.requestMethod ? "button" : "submit"
            }
            data-cy="abha-login-phone-number-field"
            required
            onButtonClick={props.onOTPButtonClick}
            onChange={(event) => {
              props.setHealthAuthentication({
                ...props.healthAuthentication,
                [event.target.name]: event.target.value
              });
            }}
          />
          {props.abdm.auth.otp.transactionId && (
            <OTPInputBox
              name="otp"
              value={props.healthAuthentication.otp}
              setValue={(otpValue) => {
                props.setHealthAuthentication({
                  ...props.healthAuthentication,
                  otp: otpValue
                });
              }}
              required
              className="margin-bottom-larger"
            />
          )}
        </section>
        <section>
          <Button
            text="Continue"
            boxShadow={false}
            required
            type="submit"
            className={"margin-top-default margin-bottom-medium"}
            disabled={
              props.healthAuthentication.otp.length !== 6 ? true : false
            }
            loading={props.abdm.loading}
          />
          {/* For switching the login method */}
          {/* {(() => {
            const HPRDataAuthenticationMethods = [];
            props.HealthDataAuthentication.forEach((loginMethod, index) => {
              if (
                props.HealthDataAuthentication[index].type !==
                  props.healthAuthentication.authType &&
                loginMethod.allowedFor.includes(props.authMode)
              ) {
                HPRDataAuthenticationMethods.push(
                  <div
                    key={loginMethod.type}
                    className=" cursor-pointer text-align-center font-family-gilroy-medium font-size-small font-color-primary text-transform-uppercase margin-vertical-large"
                    onClick={() => {
                      //   if (
                      //     typeof props.abdm.auth.otp.requestMethod === "string"
                      //   ) {
                      //     props.clearABDMAuthCredentials();
                      //   }
                      props.setHealthAuthentication({
                        ...props.healthAuthentication,
                        otp: "",
                        [loginMethod.type]: "",
                        authType: loginMethod.type,
                        inputLabel: loginMethod.inputLabel,
                        inputName: loginMethod.inputName,
                        inputType: loginMethod.inputType
                      });
                    }}
                  >
                    {loginMethod.text(props.authMode)}
                  </div>
                );
              }
            });
            return HPRDataAuthenticationMethods;
          })()} */}
          <AuthModeSwitch
            authMode={props.authMode}
            onClick={(selectedAuthMode) => {
              props.setAuthMode(selectedAuthMode);
              if (typeof props.abdm.auth.otp.requestMethod === "string") {
                props.clearABDMAuthCredentials();
              }
            }}
          />
        </section>
      </form>
    </section>
  );
};

const HPRRegisterComponent = (props) => {
  return (
    <section className="remaining-body-height max-width-588px margin-horizontal-auto padding-right-large padding-left-large overflow-y-auto inherit-parent-width ">
      {props.registrationStep === "verifyAadhaar" && (
        <RegisterFirstStep
          onAadhaarButtonClick={props.onAadhaarButtonClick}
          onChange={props.onChange}
          onFirstStepFormSubmit={props.onFirstStepFormSubmit}
          suffixButtonDisabled={props.suffixButtonDisabled}
          authMode={props.authMode}
          setAuthMode={props.setAuthMode}
          abdm={props.abdm}
          HealthDataAuthentication={props.HealthDataAuthentication}
          healthAuthentication={props.healthAuthentication}
          setHealthAuthentication={props.setHealthAuthentication}
        />
      )}
      {props.registrationStep === "verifyMobile" && (
        <RegisterSecondStep
          onChange={props.onChange}
          onSecondStepFormSubmit={props.onSecondStepFormSubmit}
          suffixButtonDisabled={props.suffixButtonDisabled}
          authMode={props.authMode}
          setAuthMode={props.setAuthMode}
          abdm={props.abdm}
          onVerifyMobileOTPButtonOnClick={props.onVerifyMobileOTPButtonOnClick}
          HealthDataAuthentication={props.HealthDataAuthentication}
          healthAuthentication={props.healthAuthentication}
          setHealthAuthentication={props.setHealthAuthentication}
        />
      )}
      {props.registrationStep === "createHPR" && (
        <RegisterThirdStep
          fromOnChange={props.fromOnChange}
          onThirdStepFormSubmit={props.onThirdStepFormSubmit}
          createButtonDisabled={props.createButtonDisabled}
          HPRIdOutOfFocus={props.HPRIdOutOfFocus}
          formData={props.formData}
          suffixButtonDisabled={props.suffixButtonDisabled}
          authMode={props.authMode}
          setAuthMode={props.setAuthMode}
          abdm={props.abdm}
          setFormData={props.setFormData}
          HealthDataAuthentication={props.HealthDataAuthentication}
          healthAuthentication={props.healthAuthentication}
          setHealthAuthentication={props.setHealthAuthentication}
        />
      )}
    </section>
  );
};

const RegisterFirstStep = (props) => {
  return (
    <form
      className="padding-right-large padding-left-large inherit-parent-height  flex-justify-content-space-between flex-direction-column"
      onSubmit={props.onFirstStepFormSubmit}
    >
      <section>
        <div className="line-height-140-percentage text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary text-transform-uppercase padding-right-small padding-left-small padding-top-large padding-bottom-larger ">
          Create Healthcare Professionals Registry
        </div>
        <InputBox
          className="inherit-parent-width border-radius-default margin-bottom-default"
          name={props.healthAuthentication.inputName}
          label={props.healthAuthentication.inputLabel}
          type={props.healthAuthentication.inputType}
          otpFocus={true}
          autoComplete="off"
          value={
            props.healthAuthentication[props.healthAuthentication.authType]
          }
          buttonTile={
            !!props.abdm.auth.otp.requestMethod ? "Resend OTP" : "Get OTP"
          }
          suffixButtonDisabled={props.suffixButtonDisabled}
          buttonType={!!props.abdm.auth.otp.requestMethod ? "button" : "submit"}
          data-cy="abha-login-phone-number-field"
          validation={(value) =>
            validation(props.healthAuthentication.inputName, value)
          }
          required
          onButtonClick={props.onAadhaarButtonClick}
          onChange={props.onChange}
        />
        {props.abdm.auth.otp.requestMethod ===
          props.healthAuthentication.authType && (
          <OTPInputBox
            className="margin-bottom-larger"
            name="otp"
            value={props.healthAuthentication.otp}
            setValue={(otpValue) => {
              props.setHealthAuthentication({
                ...props.healthAuthentication,
                otp: otpValue
              });
            }}
            required
          />
        )}
      </section>
      <section>
        <Button
          text="Verify Aadhaar"
          boxShadow={false}
          type="submit"
          className={"margin-top-default margin-bottom-medium"}
          disabled={props.healthAuthentication.otp.length !== 6 ? true : false}
          loading={props.abdm.loading}
        />
        {/* for switching login method */}
        {/* {(() => {
            const HPRDataAuthenticationMethods = [];
            props.HealthDataAuthentication.forEach((loginMethod, index) => {
              if (
                props.HealthDataAuthentication[index].type !==
                  props.healthAuthentication.authType &&
                loginMethod.allowedFor.includes(props.authMode)
              ) {
                HPRDataAuthenticationMethods.push(
                  <div
                    key={loginMethod.type}
                    className=" cursor-pointer text-align-center font-family-gilroy-medium font-size-small font-color-primary text-transform-uppercase margin-vertical-large"
                    onClick={() => {
                      //   if (
                      //     typeof props.abdm.auth.otp.requestMethod === "string"
                      //   ) {
                      //     props.clearABDMAuthCredentials();
                      //   }
                      props.setHealthAuthentication({
                        ...props.healthAuthentication,
                        otp: "",
                        [loginMethod.type]: "",
                        authType: loginMethod.type,
                        inputLabel: loginMethod.inputLabel,
                        inputName: loginMethod.inputName,
                        inputType: loginMethod.inputType
                      });
                    }}
                  >
                    {loginMethod.text(props.authMode)}
                  </div>
                );
              }
            });

            return HPRDataAuthenticationMethods;
          })()} */}
        <AuthModeSwitch
          authMode={props.authMode}
          onClick={(selectedAuthMode) => {
            props.setAuthMode(selectedAuthMode);
            //   setHealthAuthentication({
            //     authType: "phoneNumber",
            //     healthId: "",
            //     abhaNumber: "",
            //     email: "",
            //     otp: "",
            //     aadhaarNumber: "",
            //     phoneNumber: "",
            //     inputName: "phoneNumber",
            //     inputType: "tel",
            //     inputLabel: "Phone number"
            //   });
            //   if (typeof props.abdm.auth.otp.requestMethod === "string") {
            //     props.clearABDMAuthCredentials();
            //   }
            // if (typeof props.abdm.auth.otp.requestMethod === "string") {
            //   props.clearABDMAuthCredentials();
            // }
          }}
        />
      </section>
    </form>
  );
};

const RegisterSecondStep = (props) => {
  return (
    <form
      className="padding-right-large padding-left-large inherit-parent-height  flex-justify-content-space-between flex-direction-column"
      onSubmit={props.onSecondStepFormSubmit}
    >
      <section>
        <div className=" line-height-140-percentage text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary text-transform-uppercase padding-right-small padding-left-small padding-top-large padding-bottom-larger ">
          {props.authMode === "login"
            ? "Login with Healthcare Professionals Registry."
            : "Create Healthcare Professionals Registry."}
        </div>
        <InputBox
          className="inherit-parent-width border-radius-default margin-bottom-default"
          name={"phoneNumber"}
          label={"Phone Number"}
          type={"tel"}
          otpFocus={true}
          autoComplete="off"
          value={props.healthAuthentication["phoneNumber"]}
          buttonType={!!props.abdm.auth.otp.requestMethod ? "button" : "submit"}
          data-cy="abha-login-phone-number-field"
          validation={(value) => validation("phoneNumber", value)}
          onChange={props.onChange}
          required
        />
        {props.abdm.isMobileVerified === false && (
          <OTPInputBox
            name="otp"
            value={props.healthAuthentication.otp}
            setValue={(otpValue) => {
              props.setHealthAuthentication({
                ...props.healthAuthentication,
                otp: otpValue
              });
            }}
            required
            className="margin-bottom-larger"
          />
        )}
      </section>
      <section>
        {props.abdm.isMobileVerified === null || props.abdm.isMobileVerified ? (
          <Button
            text={"Verify mobile"}
            boxShadow={false}
            required
            type={"submit"}
            className={"margin-top-default margin-bottom-medium"}
            disabled={props.verifyMobileDisabled}
            loading={props.abdm.loading}
          />
        ) : (
          <Button
            text={"Verify OTP"}
            boxShadow={false}
            required
            type={"button"}
            className={"margin-top-default margin-bottom-medium"}
            disabled={props.verifyMobileOTPDisabled}
            onClick={props.onVerifyMobileOTPButtonOnClick}
            loading={props.abdm.loading}
          />
        )}
      </section>
    </form>
  );
};

const RegisterThirdStep = (props) => {
  return (
    <form
      className="padding-right-large padding-left-large inherit-parent-height  flex-justify-content-space-between flex-direction-column"
      onSubmit={props.onThirdStepFormSubmit}
      onChange={props.fromOnChange}
    >
      <section>
        <div className="line-height-140-percentage text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary text-transform-uppercase padding-right-small padding-left-small padding-top-large padding-bottom-larger ">
          {props.authMode === "login"
            ? "Login with Healthcare Professionals Registry."
            : "Create Healthcare Professionals Registry."}
        </div>

        <NativeSelect
          size="half"
          className=""
          name="subcategory"
          label="Professional subcategory"
          labelClassName="letter-spacing-4-percentage"
          defaultValue={props.formData.subcategory}
          options={[
            "",
            "Modern medicine",
            "Dentist",
            "Ayurveda",
            "Unani",
            "Sidha",
            "Homeopathy"
          ]}
        />
        <InputBox
          className="inherit-parent-width border-radius-default margin-bottom-default"
          name={"hprId"}
          label={"Healthcare professional ID"}
          type={"text"}
          otpFocus={true}
          autoComplete="off"
          value={props.formData.hprID}
          data-cy="abha-login-phone-number-field"
          onOutOfFocus={props.HPRIdOutOfFocus}
          required
        />
        <div className="display-flex flex-justify-content-space-between padding-bottom-large">
          <InputBox
            labelClassName="letter-spacing-4-percentage"
            data-cy="password"
            className="padding-right-medium"
            name="password"
            label="Set password"
            value={props.formData.password}
            type="password"
            size="half"
            validation={(value) => validation("password", value)}
          />
          <InputBox
            labelClassName="letter-spacing-4-percentage"
            data-cy="confirmPassword"
            className="padding-left-medium"
            name="confirmPassword"
            size="half"
            label="Confirm password"
            value={props.formData.confirmPassword}
            type="password"
            validation={(value) =>
              validation("confirmPassword", value, props.formData.password)
            }
          />
        </div>
        <InputBox
          className="inherit-parent-width border-radius-default margin-bottom-default"
          name={"email"}
          label={"Email"}
          type={"email"}
          otpFocus={true}
          autoComplete="off"
          value={props.formData.email}
          data-cy="abha-login-phone-number-field"
          validation={(value) => validation("email", value)}
          required
          onChange={props.onChange}
        />
        <div className="display-flex flex-justify-content-space-between padding-bottom-large">
          <NativeSelect
            labelClassName="letter-spacing-4-percentage"
            selectClassName="text-transform-capitalize  padding-top-large"
            optionsClassName="text-transform-capitalize"
            name="stateCode"
            defaultValue={props.formData.stateCode}
            label="State"
            required
            size="half"
            data-cy="stateCode"
            options={stateOptionsWithCode}
          />
          <NativeSelect
            labelClassName="letter-spacing-4-percentage"
            selectClassName="text-transform-capitalize  padding-top-large"
            optionsClassName="text-transform-capitalize"
            name="districtCode"
            label="District"
            required
            size="half"
            data-cy="districtCode"
            defaultValue={props.formData.districtCode}
            options={stateCodeWithDistricts[props.formData.stateCode]}
          />
        </div>
      </section>
      <section>
        <Button
          text="create"
          boxShadow={false}
          type="submit"
          className={"margin-top-default margin-bottom-medium"}
          disabled={props.createButtonDisabled}
          loading={props.abdm.loading}
          required
        />
      </section>
    </form>
  );
};

const AuthModeSwitch = ({ authMode, onClick }) => {
  return (
    <div
      className=" cursor-pointer font-size-small text-align-center margin-top-larger padding-bottom-large"
      onClick={() => {
        onClick(authMode === "login" ? "register" : "login");
      }}
    >
      <span className=" font-family-gilroy-regular font-color-secondary padding-right-smaller">
        {authMode === "login"
          ? "Don’t have a HPR ID?"
          : "Already have a HPR ID? "}
      </span>
      <span className=" font-family-gilroy-medium font-color-primary">
        {authMode === "login" ? " Create new" : "Login"}
      </span>
    </div>
  );
};

const ABDMButton = (props) => {
  return (
    <div
      className=" cursor-pointer display-flex flex-direction-column flex-align-items-center width-fit-content padding-horizontal-large border-1px-solid-f1f1f1 border-radius-default margin-right-larger"
      onClick={props.onClick}
    >
      <div className=" display-flex flex-align-items-center padding-top-larger padding-bottom-small ">
        <span className=" font-size-medium font-family-gilroy-medium font-color-primary  padding-right-default">
          {props.name}
        </span>
        <img src={nextIconGreen} alt="" height="10" width="6" />
      </div>
      <div className=" font-size-small font-family-gilroy-regular font-color-secondary padding-bottom-larger  padding-left-small padding-right-small">
        {props.detail}
      </div>
    </div>
  );
};

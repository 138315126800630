import React, { useRef, useState } from "react";
import { useEffect } from "react";
import inputErrorIcon from "../../assets/inputError.svg";
import inputValidIcon from "../../assets/inputValid.svg";
import "../../design-system.css";
import "./FileInputBox.css";
import PropTypes from "prop-types";

//image/jpeg,image/png,application/pdf
function FileInputBox(props) {
  const [file, setFile] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const [error, setError] = useState();
  const label = useRef(null);
  const inputRef = useRef(null);
  // set default file
  useEffect(() => {
    if (props.defaultValue) {
      setFile(props.defaultValue);
    }
  }, [props.defaultValue]);

  const handleTextInput = (event) => {
    setFile(event.target.files[0]);
    validators(event);
    setReadOnly(true);
  };

  // set editable status
  useEffect(() => {
    if (props.defaultValue) {
      setReadOnly(true);
    }
  }, [props.defaultValue]);

  const validators = (event) => {
    let isValidInput = true;
    if (event.target.files[0]) {
      if (typeof props.accept === "string") {
        let acceptedTypesString = props.accept;
        let acceptedTypesList = acceptedTypesString.split(",");
        acceptedTypesList = acceptedTypesList.filter(
          (type) => type === event.target.files[0].type
        );
        if (acceptedTypesList.length === 0) {
          isValidInput = false;
          setError("Please select a valid File");
        } else {
          isValidInput = true;
          setError(null);
        }
      } else if (!(event.target.files[0].type === "image/png")) {
        isValidInput = false;
        setError("Please select a valid File");
      } else {
        isValidInput = true;
        setError(null);
      }
    } else {
      isValidInput = false;
      setError("Please select a File");
    }
    props.isValidInput && props.isValidInput(isValidInput);
    if (isValidInput) {
      inputRef.current.classList.remove("error");
    } else {
      inputRef.current.classList.add("error");
    }
  };

  const InputSuffixIcon = () => {
    if (props.loading === true) {
      return (
        <div
          className="circular-loader"
          data-cy={`${props.name}-file-input-box-loader-icon`}
        />
      );
    } else if (typeof error === "string") {
      return (
        <img
          className={`height-width-1em ${props.iconClassNames}`}
          src={inputErrorIcon}
          alt="inputErrorIcon"
          data-cy={`${props.name}-file-input-box-error-icon`}
        />
      );
    } else if (file && props.complete === true) {
      return (
        <img
          className={`height-width-1em ${props.iconClassNames}`}
          src={inputValidIcon}
          alt="inputValidIcon"
          data-cy={`${props.name}-file-input-box-complete-icon`}
        />
      );
    } else {
      return null;
    }
  };

  const InputInfo = () => {
    if (typeof error === "string") {
      return <p className="font-size-smaller font-color-red">{error}</p>;
    } else if (typeof props.notes === "string") {
      return (
        <p className="font-size-smaller padding-top-small font-color-secondary">
          {props.notes}
        </p>
      );
    } else {
      return <p className="font-size-smaller">&nbsp;</p>;
    }
  };

  const fileName = () => {
    if (typeof file === "string") {
      return file;
    } else if (file && typeof file.name === "string") {
      return file.name;
    } else {
      return null;
    }
  };

  return (
    <div
      className={`inherit-parent-width ${
        props.className ? props.className : ``
      }`}
    >
      <div
        className={`position-relative height-48px inherit-parent-width flex-align-items-end`}
      >
        <div className="flex-center-children-horizontally font-size-medium font-family-gilroy-medium inherit-parent-width">
          <div className="inherit-parent-width">
            <input
              data-cy={
                props["data-cy"]
                  ? props["data-cy"]
                  : `${props.name}-file-input-box`
              }
              readOnly={readOnly}
              ref={inputRef}
              defaultValue={fileName()}
              className={`input-text-clip file-input-box inherit-parent-width font-family-gilroy-medium font-size-medium file-input-box-padding padding-right-4p7-em display-block min-width-250px file-input-box-border-bottom-default `}
              autoComplete={props.autoComplete}
              type="text"
              name={props.name}
              required
            />
            <span className="file-input-box-border-bottom"></span>
            <label
              htmlFor={props.name}
              ref={label}
              className={`font-color-secondary file-input-box-label position-absolute font-family-gilroy-medium font-size-medium ${
                file ||
                props.type === "time" ||
                props.type === "date" ||
                props.alwaysActive
                  ? "active"
                  : ""
              }`}
            >
              {props.label}
            </label>
          </div>
          <div className=" position-absolute  flex-align-items-center flex-justify-content-center min-width-1em right-0 top-50-percentage transform-translateY-25-percentage ">
            <InputSuffixIcon />
            <label
              htmlFor={`${props.name}-file`}
              className="cursor-pointer font-color-secondary"
            >
              <input
                data-cy={
                  props["button-data-cy"]
                    ? props["button-data-cy"]
                    : `${props.name}-file-input-box-uploader`
                }
                type="file"
                accept={props.accept}
                id={`${props.name}-file`}
                name={`${props.name}-file`}
                className="display-none inherit-parent-width"
                onChange={(event) => {
                  setReadOnly(false);
                  handleTextInput(event);
                  props.onChange && props.onChange(event);
                }}
              />
              <div className=" padding-small font-family-gilroy-medium font-size-smaller background-transparent margin-left-8px suffix-button-border border-radius-0p7em text-transform-uppercase ">
                Upload
              </div>
            </label>
          </div>
        </div>
      </div>
      <InputInfo />
    </div>
  );
}

FileInputBox.propTypes = {
  /**
   * input file default value prop
   */
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /**
   * attribute for accepted file types, and it is optional
   */
  accept: PropTypes.string,
  /**
   * function prop which returns the input is valid or not]
   * `props.isValidInput(isValidInput)` returns `true` or `false`
   */
  isValidInput: PropTypes.func,
  /**
   * loading state for the component
   */
  loading: PropTypes.bool,
  /**
   * name for input element
   */
  name: PropTypes.string.isRequired,
  /**
   * class name for icon
   */
  iconClassNames: PropTypes.string,
  /**
   * complete
   */
  complete: PropTypes.bool,
  /**
   * notes
   */
  notes: PropTypes.string,
  /**
   * class name for file input box container
   */
  className: PropTypes.string,
  /**
   * autocomplete for text input element
   */
  autoComplete: PropTypes.oneOf(["on", "off"]),
  /**
   * always active prop sets the label to active state
   */
  alwaysActive: PropTypes.bool,
  /**
   * label for the file input component
   */
  label: PropTypes.string,
  /**
   * prop which returns input onChange event
   */
  onChange: PropTypes.func,
  /**
   * selector attribute of input element for cypress testing. Default value is `${props.name}-file-input-box`
   */
  "data-cy": PropTypes.string,
  /**
   * selector attribute of input file button for cypress testing. Default value is `${props.name}-file-input-box-uploader`
   */
  "button-data-cy": PropTypes.string
};

FileInputBox.defaultProps = {
  defaultValue: null,
  accept: "",
  name: "default",
  loading: false,
  isValidInput: undefined,
  iconClassNames: "",
  complete: false,
  notes: "",
  className: "",
  autoComplete: "off",
  alwaysActive: false,
  label: "",
  onChange: undefined,
  "data-cy": "default-file-input-box",
  "button-data-cy": "default-button-data-cy"
};

export default FileInputBox;

// <button
// 	onClick={(event) => {
// 		if (props.onButtonClick) {
// 			props.onButtonClick(event);
// 		}
// 	}}
// 	className=" padding-small font-family-gilroy-medium font-size-smaller background-transparent margin-left-8px suffix-button-border border-radius-0p7em text-transform-uppercase "
// >
// 	{props.buttonTile}
// </button>{" "}

import { isValidArray, isValidObject, isValidString } from "./validators";

export const api = {
  baseUrl:
    process.env.REACT_APP_STAGING === "development"
      ? "https://api.dev.ninto.in/v1"
      : process.env.REACT_APP_STAGING === "uat"
      ? "https://api.uat.ninto.in/v1"
      : process.env.REACT_APP_STAGING === "production"
      ? "https://ninto.in/api/v1"
      : "http://localhost:5001/karthealth-dev/asia-south1/v1"
};

export const bucketNames = {
  providerIdentityDocuments:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-ninto-provider-identity-documents"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-ninto-provider-identity-documents"
      : "dev-ninto-provider-identity-documents",
  nintoProfilePictures:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-ninto-profile-pictures"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-ninto-profile-pictures"
      : "dev-ninto-profile-pictures",
  documentsUpload:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-ninto-documents-upload"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-ninto-documents-upload"
      : "dev-ninto-documents-upload",
  abdmDocumentsUpload:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-abdm-documents-upload"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-abdm-documents-upload"
      : "dev-abdm-documents-upload",
  prescriptionTemplates:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-ninto-prescription-templates"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-ninto-prescription-templates"
      : "dev-ninto-prescription-templates"
};

export const validProfileObject = (profile) => {
  if (isValidObject(profile)) {
    const doctorId =
      typeof Object.keys(profile)[0] === "string"
        ? Object.keys(profile)[0]
        : null;

    if (
      isValidObject(profile[doctorId]) &&
      profile[doctorId].hasOwnProperty("public") &&
      isValidObject(profile[doctorId].public) &&
      profile[doctorId].public.hasOwnProperty("phoneNumber") &&
      profile[doctorId].public.hasOwnProperty("uid") &&
      profile[doctorId].hasOwnProperty("private") &&
      isValidObject(profile[doctorId].private)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isProfileCompleted = (profile) => {
  if (validProfileObject(profile)) {
    const _profile = profile[Object.keys(profile)[0]];
    if (
      isValidObject(_profile.public) &&
      isValidObject(_profile.aadhar) &&
      isValidString(_profile.email) &&
      isValidString(_profile.proof) &&
      isValidObject(_profile.registrationDetails) &&
      typeof _profile.dateOfBirth === "number" &&
      isValidString(_profile.public.city) &&
      isValidArray(_profile.public.education) &&
      isValidObject(_profile.public.experience) &&
      isValidString(_profile.public.gender) &&
      isValidArray(_profile.public.speciality) &&
      isValidString(_profile.email) &&
      typeof _profile.dateOfBirth === "number" &&
      isValidString(_profile.public.city) &&
      isValidObject(_profile.public.experience) &&
      isValidString(_profile.public.gender) &&
      isValidArray(_profile.public.speciality)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const hasValidClinics = (clinics, auth) => {
  //type clinics = obj , type claims = List
  if (
    isValidObject(clinics) &&
    isValidObject(auth) &&
    isValidArray(auth.claims)
  ) {
    if (auth.claims.length !== Object.keys(clinics).length) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const filterObject = (obj, key, value) => {
  let result = {};
  if (isValidObject(obj)) {
    Object.keys(obj).filter((_key) => {
      const foo = obj[_key][key] === value;
      if (foo) {
        result[_key] = { ...result, ...obj[_key] };
      }
      return foo;
    });
    return result;
  } else {
    return null;
  }
};

export const validValue = (condition) => {
  try {
    const _condition = condition();
    if (_condition !== null && _condition !== undefined && _condition !== {}) {
      return _condition;
    } else {
      return null;
    }
  } catch (_) {
    return null;
  }
};

import {
  doc,
  setDoc,
  getDocs,
  query,
  collection,
  where,
  onSnapshot,
  updateDoc,
  addDoc,
  collectionGroup,
  deleteDoc,
  orderBy,
  writeBatch,
  limit,
  startAfter
} from "firebase/firestore";
import { getCurrentUser } from "./authentication";
import { database } from "./firebase";
import { useEffect, useRef, useState } from "react";
import { uploadIdentityDocument } from "./storage";
import { isValidArray, isValidObject } from "./validators";
import { throwError } from "./error";
import { setErrorStatus } from "../redux/status/actions";
import {
  putFollowedPatients,
  putPinnedPatients
} from "../redux/abdmPatients/actions";
import { setProfileData } from "../redux/profile/actions";
import { putConnectedClinics } from "../redux/clinics/actions";
import { setNotifications } from "../redux/notifications/actions";
import { logout } from "../redux/authentication/actions";
import { shard } from "../utils/constants";
import { setFeatureFlags, setIncidents } from "../redux/app/actions";

export async function useFeatureFlagsListener() {
  const [listener, setListener] = useState({
    document: null
  });

  const subscribeToFeatureFlags = () => {
    const featureFlagsQuery = query(doc(database, "config", "featureFlags"));

    return onSnapshot(
      featureFlagsQuery,
      (querySnapshot) => {
        setFeatureFlags(querySnapshot.data());
      },
      (error) => {
        console.error(error, "from useFeatureFlagsListener");
        setErrorStatus(error);
        logout();
      }
    );
  };
  useEffect(() => {
    if (listener.document === null) {
      setListener({
        document: subscribeToFeatureFlags()
      });
    }
    //eslint-disable-next-line
  }, []);
}

export async function useIncidentsListener(incident, isAuthed) {
  const [listener, setListener] = useState({
    document: null
  });

  const subscribeToIncidents = () => {
    const incidentsQuery = query(collection(database, "incidents"));

    return onSnapshot(
      incidentsQuery,
      (querySnapshot) => {
        let data = {};
        querySnapshot.forEach((doc) => {
          data = { ...data, [doc.id]: { ...doc.data(), incidentId: doc.id } };
        });
        setIncidents(data);
      },
      (error) => {
        console.error(error, "from useIncidentsListener");
        setErrorStatus(error);
        logout();
      }
    );
  };
  useEffect(() => {
    if (listener.document === null && isAuthed && incident) {
      setListener({
        document: subscribeToIncidents()
      });
    } else if (
      typeof listener.document === "function" &&
      (!isAuthed || !incident)
    ) {
      listener.document();
      setListener({ document: null });
    }
    //eslint-disable-next-line
  }, [isAuthed, incident]);
}

export const customAddDoc = async (ref, data) => {
  let doc;
  try {
    doc = await addDoc(ref, data);
  } catch (error) {
    if (error.message.includes("Document already exists")) {
      doc = {};
      doc.id = error.message.split("/")[error.message.split("/").length - 1];
      console.warn("Document creation ID collision at create profile");
    } else {
      throw error;
    }
  }
  return doc.id;
};

export async function createProfile(uid) {
  const currentUser = getCurrentUser();
  const rootRef = collection(database, "clinicStaffs");
  const addRoot = await addDoc(rootRef, {
    fullName: JSON.parse(currentUser.displayName).doctor,
    uid: uid,
    phoneNumber: currentUser.phoneNumber
  });

  //set clinicStaffs Private profile
  const privateRef = doc(
    database,
    "clinicStaffs",
    addRoot.id,
    "clinicStaffsPrivate",
    "profile"
  );
  await setDoc(privateRef, {
    uid: uid,
    phoneNumber: currentUser.phoneNumber,
    fullName: JSON.parse(currentUser.displayName).doctor,
    profileId: addRoot.id
  });

  //set clinicStaffs public profile

  const publicRef = doc(
    database,
    "clinicStaffs",
    addRoot.id,
    "clinicStaffsPublic",
    "profile"
  );
  await setDoc(publicRef, {
    fullName: JSON.parse(currentUser.displayName).doctor,
    uid: uid,
    phoneNumber: currentUser.phoneNumber,
    profileId: addRoot.id
  });

  return uid;
}

export async function editProfile(id, data, connections) {
  let frontFileMeta;
  let backFileMeta;
  let proofFileMeta;

  if (data.aadhar && data.proof) {
    frontFileMeta = await uploadIdentityDocument(
      id,
      data.fileNames.front,
      data.aadhar.front
    );
    backFileMeta = await uploadIdentityDocument(
      id,
      data.fileNames.back,
      data.aadhar.back
    );
    proofFileMeta = await uploadIdentityDocument(
      id,
      data.fileNames.proof,
      data.proof
    );

    data.aadhar = {
      front: frontFileMeta.metadata.fullPath,
      back: backFileMeta.metadata.fullPath
    };

    data.proof = proofFileMeta.metadata.fullPath;
  }

  const clinicStaffsRootData = {
    ...(data.aadhar
      ? {
          aadhar: {
            front: frontFileMeta.metadata.fullPath,
            back: backFileMeta.metadata.fullPath
          }
        }
      : {}),
    ...(data.proof ? { proof: proofFileMeta.metadata.fullPath } : {}),
    // ...(data.phoneNumber ? { phoneNumber: data.phoneNumber } : {}),
    ...(data.uid ? { uid: data.uid } : {}),
    // ...(data.did ? { did: data.did } : {}),
    ...(data.registrationDetails
      ? {
          registrationDetails: {
            registrationNumber: data.registrationDetails.registrationNumber,
            registrationCouncil: data.registrationDetails.registrationCouncil,
            registrationYear: data.registrationDetails.registrationYear
          }
        }
      : {}),
    ...(data.email ? { email: data.email } : {}),
    ...(data.dateOfBirth ? { dateOfBirth: data.dateOfBirth } : {}),
    ...(data.fullName ? { fullName: data.fullName } : {})
  };

  let educationData = [];
  if (data.education) {
    data.education.forEach((obj) => {
      educationData.push({
        college: obj.college,
        degree: obj.degree,
        yearOfCompletion: obj.yearOfCompletion
      });
    });
  }

  let specialityData = [];
  if (isValidArray(data.speciality)) {
    data.speciality.forEach((data) => {
      specialityData.push(data);
    });
  }

  const clinicStaffsPublicData = {
    ...(data.fullName ? { fullName: data.fullName } : ""),
    ...(data.education ? { education: educationData } : []),
    ...(data.experience
      ? { experience: { yearOfExperience: data.experience.yearOfExperience } }
      : {}),
    ...(data.city ? { city: data.city } : {}),
    ...(data.speciality ? { speciality: specialityData } : []),
    ...(data.gender ? { gender: data.gender } : {})
  };

  const clinicStaffsPrivateData = {
    ...(data.profilePicture ? { profilePicture: data.profilePicture } : ""),
    ...(data.fullName ? { fullName: data.fullName } : {})
  };

  const clinicStaffsConnectionsData = {
    ...(data.fullName ? { fullName: data.fullName } : {})
  };

  //set clinicStaffs root profile
  if (isValidObject(clinicStaffsRootData)) {
    const rootRef = doc(database, "clinicStaffs", id);
    await updateDoc(rootRef, clinicStaffsRootData);
  }

  //set clinicStaffs public profile
  if (isValidObject(clinicStaffsPublicData)) {
    const publicRef = doc(
      database,
      "clinicStaffs",
      id,
      "clinicStaffsPublic",
      "profile"
    );
    await setDoc(publicRef, clinicStaffsPublicData, { merge: true });
  }

  //set clinicStaffs private profile
  if (isValidObject(clinicStaffsPrivateData)) {
    const publicRef = doc(
      database,
      "clinicStaffs",
      id,
      "clinicStaffsPrivate",
      "profile"
    );
    await setDoc(publicRef, clinicStaffsPrivateData, { merge: true });
  }

  if (isValidObject(connections)) {
    Object.keys(connections).forEach((connectionId) => {
      const connectionsRef = doc(
        database,
        "clinicStaffs",
        id,
        "connections",
        connectionId
      );
      setDoc(connectionsRef, clinicStaffsConnectionsData, { merge: true });
    });
  }
}

export function useClinicStaffsListener(props) {
  console.log(props, "props");
  const [clinicStaffsRootData, setClinicStaffsRootData] = useState({});
  const [clinicStaffsPublicData, setClinicStaffsPublicData] = useState({});
  const [clinicStaffsPrivateData, setClinicStaffsPrivateData] = useState({});
  const listeners = useRef({
    rootData: null,
    publicData: null,
    privateData: null
  });

  const clinicStaffsRootListener = () => {
    const clinicStaffsRoot = query(
      collection(database, "clinicStaffs"),
      where("phoneNumber", "==", props.phoneNumber)
    );

    return onSnapshot(
      clinicStaffsRoot,
      (dataSnapshot) => {
        dataSnapshot.forEach((doc) => {
          setClinicStaffsRootData({ [doc.id]: doc.data() });
        });
      },
      (error) => {
        console.error("error RootListener", error);
        setErrorStatus(error);
        logout();
      }
    );
  };

  const clinicStaffsPublicListener = () => {
    const clinicStaffsPublic = query(
      collectionGroup(database, "clinicStaffsPublic"),
      where("phoneNumber", "==", props.phoneNumber)
    );
    return onSnapshot(
      clinicStaffsPublic,
      (dataSnapshot) => {
        dataSnapshot.forEach((doc) => {
          setClinicStaffsPublicData(doc.data());
        });
      },
      (error) => {
        console.error("error PublicListener", error);
        setErrorStatus(error);
        logout();
      }
    );
  };

  const clinicStaffsPrivateListener = () => {
    const clinicStaffsPrivate = query(
      collectionGroup(database, "clinicStaffsPrivate"),
      where("phoneNumber", "==", props.phoneNumber)
    );
    return onSnapshot(
      clinicStaffsPrivate,
      (dataSnapshot) => {
        dataSnapshot.forEach((doc) => {
          setClinicStaffsPrivateData(doc.data());
        });
      },
      (error) => {
        console.error("error PrivateListener", error);
        setErrorStatus(error);
        logout();
      }
    );
  };

  useEffect(() => {
    if (
      isValidObject(clinicStaffsRootData) &&
      isValidObject(clinicStaffsPublicData) &&
      isValidObject(clinicStaffsPrivateData)
    ) {
      setProfileData({
        [Object.keys(clinicStaffsRootData)]: {
          ...clinicStaffsRootData[Object.keys(clinicStaffsRootData)],
          public: clinicStaffsPublicData,
          private: clinicStaffsPrivateData
        }
      });
    }
  }, [clinicStaffsRootData, clinicStaffsPublicData, clinicStaffsPrivateData]);

  useEffect(() => {
    if (props.uid && props.phoneNumber && props.isAuthed) {
      listeners.current.rootData = clinicStaffsRootListener();
      listeners.current.publicData = clinicStaffsPublicListener();
      listeners.current.privateData = clinicStaffsPrivateListener();
    } else {
      Object.keys(listeners.current).forEach((key) => {
        if (typeof listeners.current[key] === "function") {
          listeners.current[key]();
        }
      });
    }
    // eslint-disable-next-line
  }, [props.uid, props.phoneNumber, props.isAuthed]);
}

export async function setNotificationRead(notificationId, clinicId) {
  const notificationRef = doc(
    database,
    "clinicStaffs",
    clinicId,
    "notifications",
    notificationId
  );
  await updateDoc(notificationRef, {
    read: true
  });
  return { success: true };
}

export async function getPaginatedNotifications(
  profileId,
  lastVisible,
  pageNumber,
  update
) {
  const notifications = {};
  const validDate = new Date().setHours(0, 0, 0, 0);
  const paginationNotifications = query(
    collection(database, "clinicStaffs", profileId, "notifications"),
    // where("shard", "in", shard.value),
    where("timestamp", "<=", +validDate + 86400000 * 2),
    orderBy("timestamp", "desc"),
    startAfter(lastVisible),
    limit(50)
  );

  const querySnapshot = await getDocs(paginationNotifications);
  querySnapshot.forEach((doc) => {
    if (isValidObject(doc.data())) {
      notifications[doc.id] = {
        documentId: doc.id,
        ...(update
          ? { pageNumber: pageNumber }
          : { pageNumber: pageNumber + 1 }),
        ...doc.data()
      };
    }
  });
  const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
  return {
    notifications: notifications,
    lastVisible: isValidObject(lastVisibleDoc) ? lastVisibleDoc : "end"
  };
}

export function useNotificationListener(props) {
  const [notificationListener, setNotificationListener] = useState({
    listener: null
  });
  const subscribeToNotification = (profileId, auth) => {
    if (!profileId && !auth) {
      return;
    }

    const currentDate = +new Date().setHours(0, 0, 0, 0);
    const notificationQuery = query(
      collection(database, "clinicStaffs", profileId, "notifications"),
      where("expiresAt.shard", "in", shard.value),
      where("timestamp", ">=", currentDate),
      orderBy("timestamp", "desc"),
      limit(50)
    );
    return onSnapshot(
      notificationQuery,
      (querySnapshot) => {
        let notifications = {};
        querySnapshot.forEach((doc) => {
          notifications[doc.id] = {
            documentId: doc.id,
            pageNumber: 0,
            ...doc.data()
          };
        });
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setNotifications(notifications, lastVisible, profileId);
      },
      (error) => {
        console.error("from notification listener for", profileId, error);

        setErrorStatus(error);
        logout();
      }
    );
  };

  // notification listener
  useEffect(() => {
    if (
      props.isAuthed === true &&
      typeof props.profileId === "string" &&
      notificationListener.listener === null
    ) {
      setNotificationListener({
        listener: subscribeToNotification(props.profileId, props.isAuthed)
      });
    } else if (
      props.isAuthed === false &&
      typeof props.profileId !== "string" &&
      typeof notificationListener.listener === "function"
    ) {
      notificationListener.listener();
      setNotificationListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.isAuthed, props.profileId]);
}

//mark all as read database function
export async function setAllNotificationAsRead(notificationsList) {
  if (isValidArray(notificationsList)) {
    const batch = writeBatch(database);
    notificationsList?.forEach((notification) => {
      const documentRef = doc(
        database,
        "clinicStaffs",
        notification?.profileId,
        "notifications",
        notification?.documentId
      );
      batch.update(documentRef, { read: true });
    });
    await batch.commit();
    return { success: true };
  } else {
    throwError("custom", "Something went wrong");
  }
}

export const createQuery = async (data) => {
  await addDoc(collection(database, "queries"), data);
};

export async function followPatient(data) {
  const collectionRef = doc(
    database,
    "clinicStaffs",
    data.doctorId,
    "followedPatients",
    data.patient.patientId
  );

  await setDoc(collectionRef, data);
}

export async function unFollowPatient(doctorId, deleteId, updateData) {
  await deleteDoc(
    doc(database, "clinicStaffs", doctorId, "followedPatients", deleteId)
  );

  if (typeof updateData.patientId === "string") {
    await updateDoc(
      doc(
        database,
        "clinicStaffs",
        doctorId,
        "followedPatients",
        updateData.patientId
      ),
      {
        successivePatient: updateData.successivePatient
      }
    );
  }
}

export async function unPinPatient(doctorId, deleteId, updateData) {
  await deleteDoc(
    doc(database, "clinicStaffs", doctorId, "pinnedPatients", deleteId)
  );

  if (typeof updateData.patientId === "string") {
    await updateDoc(
      doc(
        database,
        "clinicStaffs",
        doctorId,
        "pinnedPatients",
        updateData.patientId
      ),
      {
        successivePatient: updateData.successivePatient
      }
    );
  }
}

export async function usePinnedPatientsListener(props) {
  const [pinnedPatientsListener, setPinnedPatientsListener] = useState({
    listener: null
  });

  const subscribeToPinnedPatients = (uid, currentClinic) => {
    const collectionQueryRef = query(
      collection(database, "clinicStaffs", uid, "pinnedPatients"),
      where("pinnedBy.clinicId", "==", currentClinic),
      where("doctorId", "==", uid)
    );

    return onSnapshot(
      collectionQueryRef,
      (querySnapshot) => {
        let pinnedPatientsDocs = {};
        querySnapshot.forEach((doc) => {
          if (doc.data()) {
            pinnedPatientsDocs[doc.id] = doc.data();
          }
        });
        putPinnedPatients(pinnedPatientsDocs);
      },
      (error) => {
        console.error("ERROR useFollowingAndPinnedPatientsSubscriber", error);
        setErrorStatus(error);
        logout();
      }
    );
  };

  useEffect(() => {
    if (props.isAuthed === true && props.uid && props.currentClinic) {
      setPinnedPatientsListener({
        listener: subscribeToPinnedPatients(props.uid, props.currentClinic)
      });
    } else if (
      props.isAuthed === false &&
      typeof pinnedPatientsListener.listener === "function"
    ) {
      pinnedPatientsListener.listener();
      setPinnedPatientsListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.uid, props.isAuthed, props.currentClinic]);
}

export async function useFollowingPatientsListener(props) {
  const [followingPatientsListener, setFollowingPatientsListener] = useState({
    listener: null
  });
  const subscribeToFollowingPatients = (uid, currentClinic) => {
    const collectionQueryRef = query(
      collection(database, "clinicStaffs", uid, "followedPatients"),
      where("pinnedBy.clinicId", "==", currentClinic),
      where("doctorId", "==", uid)
    );

    return onSnapshot(
      collectionQueryRef,
      (querySnapshot) => {
        let followingPatientsDocs = {};
        querySnapshot.forEach((doc) => {
          if (doc.data()) {
            followingPatientsDocs[doc.id] = doc.data();
          }
        });

        putFollowedPatients(followingPatientsDocs);
      },
      (error) => {
        console.error("ERROR useFollowingPatientsListener", error);
        setErrorStatus(error);
        logout();
      }
    );
  };

  useEffect(() => {
    if (props.isAuthed === true && props.uid && props.currentClinic) {
      setFollowingPatientsListener({
        listener: subscribeToFollowingPatients(props.uid, props.currentClinic)
      });
    } else if (
      props.isAuthed === false &&
      typeof followingPatientsListener.listener === "function"
    ) {
      followingPatientsListener.listener();
      setFollowingPatientsListener({
        listener: null
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.uid, props.currentClinic, props.isAuthed]);
}

export async function useConnectionsListener(props) {
  const [connectionsListener, setConnectionsListener] = useState({
    listener: null
  });
  const subscribeToConnections = (phoneNumber, uid) => {
    if (phoneNumber && uid) {
      const collectionQueryRef = query(
        collectionGroup(database, "connections"),
        where("phoneNumber", "==", phoneNumber),
        where("userType", "==", "doctor")
      );
      return onSnapshot(
        collectionQueryRef,
        (querySnapshot) => {
          let connectionsData = {};
          querySnapshot.forEach((doc) => {
            if (doc.data()) {
              connectionsData[doc.data().connectionId] = {
                ...doc.data()
              };
            }
          });
          console.log(connectionsData, "connectionsData");
          putConnectedClinics(connectionsData);
        },
        (error) => {
          console.error("error connection Listener", error);
          setErrorStatus(error);
          logout();
        }
      );
    }
  };

  useEffect(() => {
    if (props.isAuthed === true && props.uid && props.phoneNumber) {
      setConnectionsListener({
        listener: subscribeToConnections(props.phoneNumber, props.uid)
      });
    } else if (
      props.isAuthed === false &&
      typeof connectionsListener.listener === "function"
    ) {
      connectionsListener.listener();
      setConnectionsListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.uid, props.phoneNumber, props.isAuthed]);
}

export async function removeConnectedClinic(doctorUID, clinicId) {
  if (doctorUID && clinicId) {
    await updateDoc(
      doc(database, "clinicStaffs", doctorUID, "connections", clinicId),
      {
        delete: true
      }
    );
    return true;
  } else {
    throwError("custom", "Something went wrong");
  }
}

export async function removeClinicRequest(requestDocumentId) {
  if (requestDocumentId) {
    await deleteDoc(doc(database, "requests", requestDocumentId));
    return true;
  } else {
    throwError("custom", "Something went wrong");
  }
}

export async function isPatientSubscribed(healthId, clinicId) {
  const collectionQueryRef = query(
    collection(database, "clinics", clinicId, "subscriptions"),
    where("subscription.patient.id", "==", healthId)
  );
  const querySnapshot = await getDocs(collectionQueryRef);
  const result = [];
  if (isValidObject(querySnapshot) && isValidArray(querySnapshot.docs)) {
    querySnapshot.forEach((doc) => {
      if (isValidObject(doc.data())) {
        result.push({ ...doc.data(), documentId: doc.id });
      }
    });
  }
  return result;
}

export async function isConsentRequestPresent(healthId, clinicId) {
  const consentRequestsDocuments = query(
    collection(database, "clinics", clinicId, "consentRequests"),
    where("healthId", "==", healthId),
    where("active", "==", true)
  );

  const consentRequestsQuerySnapshot = await getDocs(consentRequestsDocuments);

  const result = {};
  consentRequestsQuerySnapshot.forEach((doc) => {
    result[doc.id] = { ...doc.data(), documentId: doc.id };
  });

  return result;
}

export async function getDataTransfer(patientId, currentClinicId) {
  let result = [];

  const collectionQueryRef1 = query(
    collection(database, "clinics", currentClinicId, "dataTransfers"),
    where("patient.patientId", "==", patientId)
  );
  const querySnapshot1 = await getDocs(collectionQueryRef1);
  querySnapshot1.forEach((doc) => {
    result.push({ ...doc.data(), documentId: doc.id });
  });

  return result;
}

export async function getPatientDocuments(patientId, currentClinicId) {
  let result = [];

  const collectionQueryRef1 = query(
    collection(database, "clinics", currentClinicId, "transferredDocuments"),
    where("patient.patientId", "==", patientId)
  );
  const querySnapshot1 = await getDocs(collectionQueryRef1);
  querySnapshot1.forEach((doc) => {
    result.push({ ...doc.data(), documentId: doc.id });
  });
  return result;
}

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./SignUp.css";
import Button from "../../front-end-global-components/components/Button/Button";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import { reCaptchaVerifier } from "../../services/authentication";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import Header from "../../front-end-global-components/components/Header/Header";
import { setErrorStatus } from "../../redux/status/actions";
import {
  clearCredentials,
  sendOtp,
  verifyOtp
} from "../../redux/authentication/actions";
import { mockAuthVerifyOtp } from "../../redux/mock/actions";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import FeatureFlagsFallback from "../../front-end-global-components/components/FeatureFlagsFallback/FeatureFlagsFallback";
import HandleBackClick from "../../front-end-global-components/components/HandleBackClick/HandleBackClick";

function SignUp(props) {
  const [signUpFormData, setSignUpFormData] = useState({
    userName: "Dr.",
    phoneNumber: "+91",
    OTP: null,
    agreeTo: false
  });

  const [showTermsAndConditionModal, setShowTermsAndConditionModal] =
    useState(false);

  //invisible recaptcha for authentication
  useEffect(() => {
    window.reCaptchaVerifier = reCaptchaVerifier();
  }, []);

  const setOTPValue = (otp) => {
    if (signUpFormData.phoneNumber === null) {
    }
    setSignUpFormData({
      ...signUpFormData,
      OTP: otp
    });
  };

  const signUpFormOnChange = (event) => {
    //To clear auth verification while user trying to change phone number after otp sent
    if (
      event.target.name === "phoneNumber" &&
      props.auth.credentials.verificationId &&
      signUpFormData.phoneNumber !== event.target.value
    ) {
      //clear auth verification
      props.clearCredentials();
    }
    if (event.target.name.includes("otp")) {
      return;
    } else {
      if (event.target.type === "checkbox") {
        setSignUpFormData({
          ...signUpFormData,
          [event.target.name]: event.target.checked
        });
      } else {
        setSignUpFormData({
          ...signUpFormData,
          [event.target.name]: event.target.value
        });
      }
    }
  };

  const otpRequestHandler = (event) => {
    event.preventDefault();
    const userName = signUpFormData.userName.replace("Dr.", "");
    if (userName.trim().length === 0) {
      return props.setErrorStatus({
        code: "custom",
        message: "Please enter a valid name"
      });
    }
    props.sendOtp(signUpFormData.phoneNumber, "signUp");
  };

  const signUpHandler = (event) => {
    event.preventDefault();

    if (process.env.REACT_APP_STAGING === "local") {
      props.mockAuthWithOTP("signUp", signUpFormData.OTP);
      return;
    }

    props.verifyOtp(
      signUpFormData.OTP,
      signUpFormData.userName,
      signUpFormData.phoneNumber,
      "signUp"
    );
  };

  const isOtpButtonDisabled = () => {
    if (
      typeof signUpFormData.userName === "string" &&
      signUpFormData.userName.trim().length > 0 &&
      typeof signUpFormData.phoneNumber === "string" &&
      signUpFormData.phoneNumber.trim().length >= 13
    )
      return false;
    return true;
  };

  const termsAndConditionModalHandler = () => {
    setShowTermsAndConditionModal((prevState) => !prevState);
  };

  return (
    <div className="inherit-parent-height">
      <article className="inherit-parent-height">
        <Header
          userType="doctor"
          backButtonOnClick={() => {}}
          hideSupport={true}
          hideBackButton
          maxStepperCount={3}
          currentStepperValue={0}
          supportIconOnClick={() => {
            props.navigate("/queries");
          }}
        />

        <ConditionalRender condition={!props.app.featureFlags?.authentication}>
          <FeatureFlagsFallback height="remaining-body-height" />
        </ConditionalRender>

        <ConditionalRender condition={props.app.featureFlags?.authentication}>
          <form
            onChange={(event) => {
              signUpFormOnChange(event);
            }}
            onSubmit={(event) => event.preventDefault()}
            className="remaining-body-height overflow-x-scroll width-100-percent grey-abstract-background-image flex-center-children-vertically flex-direction-column"
          >
            <section className="padding-larger inherit-parent-width max-width-500px flex-grow">
              <h1 className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
                DOCTOR SIGNUP
              </h1>
              <InputBox
                className="inherit-parent-width border-radius-default margin-vertical-large"
                name="userName"
                label="Name"
                type="text"
                autoComplete="false"
                disabled={props.auth.credentials.verificationId ? true : false}
                required
                data-cy="signup-screen-name-field"
                value={signUpFormData.userName}
              />
              <InputBox
                className="inherit-parent-width border-radius-default margin-bottom-large"
                name="phoneNumber"
                label="Phone Number"
                value={signUpFormData.phoneNumber}
                type="tel"
                autoComplete="false"
                show="otpButton"
                buttonTileId="login-button"
                onButtonClick={(event) => {
                  otpRequestHandler(event);
                }}
                data-cy="signup-screen-phone-number-field"
                buttonTile={
                  props.auth.credentials.verificationId !== null
                    ? "Resend OTP"
                    : "Get OTP"
                }
                buttonType={
                  typeof props.auth.credentials.verificationId === "string"
                    ? "button"
                    : "submit"
                }
                suffixButtonDisabled={isOtpButtonDisabled()}
                required
              />
              {props.auth.credentials.verificationId && (
                <>
                  <OTPInputBox
                    name="otp"
                    setValue={setOTPValue}
                    className="margin-bottom-large"
                    required
                  />
                  <div className="inherit-parent-width display-flex padding-bottom-large text-align-left font-size-small font-family-gilroy-medium margin-top-small">
                    <CheckBox name="agreeTo" label="Agree to" />
                    <span className="margin-left-default  font-color-primary">
                      Terms and conditions.
                    </span>
                  </div>
                </>
              )}
            </section>
            <footer className="padding-larger inherit-parent-width max-width-500px">
              <Button
                loading={props.auth.loading}
                type={
                  typeof props.auth.credentials.verificationId === "string"
                    ? "submit"
                    : "button"
                }
                className="margin-bottom-large"
                text="SignUp"
                onClick={(event) => {
                  signUpHandler(event);
                }}
                disabled={
                  !(
                    typeof signUpFormData.OTP === "string" &&
                    signUpFormData.OTP.length === 6 &&
                    signUpFormData.agreeTo === true
                  )
                }
                data-cy="signup-button"
                boxShadow={false}
              />
              <h2 className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
                ALREADY HAVE AN ACCOUNT?
                <span
                  className="margin-left-default font-color-primary"
                  onClick={() => {
                    props.clearCredentials();
                    props.navigate("/login");
                  }}
                >
                  Login
                </span>
              </h2>
            </footer>
          </form>
        </ConditionalRender>
        <div id="recaptcha-placeholder"></div>
      </article>
      <HandleBackClick
        onClose={() => {
          termsAndConditionModalHandler();
        }}
        isBackEnabled={showTermsAndConditionModal}
      >
        <Modal
          onClickClose={termsAndConditionModalHandler}
          show={showTermsAndConditionModal}
          position="position-fixed top-0 left-0"
          boxShadow="none"
          borderRadius="none"
          width="inherit-parent-width"
          background="background-transparent"
          height="inherit-parent-height"
        >
          <div
            data-cy="notification-modal-back-drop"
            className="flex-place-children-page-center inherit-parent-height inherit-parent-width"
            onClick={termsAndConditionModalHandler}
          >
            <div className="inherit-parent-width max-width-500px padding-larger">
              <main
                style={{}}
                className="flex-place-children-page-center inherit-parent-width minimum-height-250px max-width-500px background-color-white border-radius-default font-family-gilroy-regular"
              >
                Terms and conditions
              </main>
            </div>
          </div>
        </Modal>
      </HandleBackClick>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    app: state.app
  };
};

const mapDispatchToProps = function () {
  return {
    verifyOtp: (otp, userName, phoneNumber, type) =>
      verifyOtp(otp, userName, phoneNumber, type),
    sendOtp: (phoneNumber, type) => sendOtp(phoneNumber, type),
    mockAuthWithOTP: (type, otp) => mockAuthVerifyOtp(type, otp),
    clearCredentials: () => clearCredentials(),
    setErrorStatus: (errorObj) => {
      setErrorStatus(errorObj);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

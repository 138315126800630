import React from "react";
import { connect } from "react-redux";

import Header from "../../front-end-global-components/components/Header/Header";

import {
  IncidentsCard,
  SelectedIncident
} from "../../front-end-global-components/components/Incidents/Incidents";
import { setSelectedIncidents } from "../../redux/app/actions";

function Incidents(props) {
  return (
    <div className="inherit-parent-height">
      <Header
        title="All Incidents"
        backButtonOnClick={() => {
          if (props.app.selectedIncident) {
            setSelectedIncidents(null);
          } else {
            props.navigate(-1);
          }
        }}
        hideSupport={true}
      />

      <div className=" remaining-body-height padding-large overflow-scroll">
        {!props.app.selectedIncident && (
          <IncidentsCard
            incidents={props.app.incidents}
            incidentOnClick={(incidentId) => {
              setSelectedIncidents(incidentId);
            }}
            userType="clinics"
          />
        )}

        {props.app.selectedIncident && (
          <SelectedIncident
            incident={props.app.incidents[props.app.selectedIncident]}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    app: state.app
  };
};

const mapDispatchToProps = function () {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Incidents);

import React, { useState } from "react";
import { connect } from "react-redux";

import Button from "../../front-end-global-components/components/Button/Button";
import Header from "../../front-end-global-components/components/Header/Header";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import TextArea from "../../front-end-global-components/components/TextArea/TextArea";
import { createQuery } from "../../redux/queries/actions";
import "./Query.css";

const Query = (props) => {
  const [queryForm, setQueryForm] = useState({
    topic: "",
    description: ""
  });

  const queryFormOnChangeHandler = (event) => {
    setQueryForm({ ...queryForm, [event.target.name]: event.target.value });
  };

  const querySubmitHandler = (event) => {
    event.preventDefault();
    let data = {
      topic: queryForm.topic,
      description: queryForm.description
    };
    props.createQuery(data, props.navigate);
  };

  return (
    <div className="inherit-parent-height">
      <Header
        title="Query"
        backButtonOnClick={() => props.navigate(-1)}
        hideSupport={true}
        incident={props.app.featureFlags?.incident?.clinics}
        incidentOnClick={() => {
          props.navigate("/incidents");
        }}
      />
      <form
        className="padding-left-larger padding-right-larger padding-top-larger"
        onChange={(event) => {
          queryFormOnChangeHandler(event);
        }}
      >
        <div className="font-family-gilroy-bold font-size-medium font-color-secondary text-align-center">
          Please let us know your issue
        </div>
        <div className=" font-family-gilroy-medium text-align-center font-size-medium font-color-secondary padding-large">
          Describe the issue you’ve encountered.
        </div>
        <InputBox
          className="inherit-parent-width border-radius-default margin-vertical-large"
          name="topic"
          removeResponsive={true}
          label="What action did you try to perform?"
          value={queryForm.topic}
        />
        <TextArea
          className="inherit-parent-width border-radius-default margin-vertical-large"
          type="text"
          name="description"
          label="Provide additional information"
          value={queryForm.description}
        />

        {/* <TextBox
					label="What action did you try to perform?"
					name="topic"
					category="dropdown"
					className=""
					dataList={["Doctor notes", "Other"]}
					value={queryForm.type}
					alwaysActive={true}
				/> */}
        {/* {queryForm.topic === "Other" && (
					<TextBox
						type="text"
						label="Provide additional information"
						name="other"
						className="margin-top-large"
						value={queryForm.other}
					/>
				)} */}
        {/* <TextBox
					label="Provide additional information"
					name="description"
					category="textarea"
					className="margin-top-large"
					// textAreaClasses=" text-area "
					value={queryForm.description}
				/> */}
      </form>
      <div className="display-flex margin-larger">
        <Button
          boxShadow={false}
          disabled={
            queryForm.topic === null ||
            queryForm.topic === "" ||
            queryForm.description === ""
              ? true
              : false
          }
          loading={props.queries.loading}
          className="margin-right-small"
          text="Submit"
          onClick={(event) => querySubmitHandler(event)}
        />
        <Button
          boxShadow={false}
          className="margin-left-small"
          variant="secondary"
          text="Cancel"
          onClick={() => {
            props.navigate(-1);
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    queries: state.queries,
    app: state.app
  };
};

const mapDispatchToProps = function () {
  return {
    createQuery: (data, navigate) => createQuery(data, navigate)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Query);

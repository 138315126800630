const appReducer = (
  state = {
    online: false,
    notification: null,
    featureFlags: null,
    incidents: null,
    selectedIncident: null
  },
  action
) => {
  switch (action.type) {
    case "SET_ONLINE":
      return {
        online: action.payload.online
      };

    case "SET_NOTIFICATION_PERMISSION":
      return {
        ...state,
        notification: action.payload.notification
      };

    case "SET_FEATURE_FLAGS":
      return {
        ...state,
        featureFlags: action.payload.featureFlags
      };

    case "SET_INCIDENTS":
      return {
        ...state,
        incidents: action.payload.incidents
      };

    case "SET_SELECTED_INCIDENTS":
      return {
        ...state,
        selectedIncident: action.payload.incidentId
      };

    case "RESET":
      return {
        ...state,
        online: false,
        notification: null,
        incidents: null,
        selectedIncident: null
      };

    default:
      return state;
  }
};
export default appReducer;

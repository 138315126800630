import store from "../store/store";
import { appActionTypes } from "./saga";
/**
 * @module AppSaga
 */

/**
 * set online status
 * @param {boolean} isOnline
 */
export function putOnline(isOnline) {
  store.dispatch({
    type: appActionTypes.PUT_ONLINE,
    payload: {
      online: isOnline
    }
  });
}

export function setNotificationPermission(status) {
  store.dispatch({
    type: appActionTypes.PUT_NOTIFICATION_PERMISSION,
    payload: {
      notification: status
    }
  });
}

export function setFeatureFlags(data) {
  store.dispatch({
    type: appActionTypes.PUT_FEATURE_FLAGS,
    payload: {
      featureFlags: data
    }
  });
}

export function setIncidents(data) {
  store.dispatch({
    type: appActionTypes.PUT_INCIDENTS,
    payload: {
      incidents: data
    }
  });
}

export function setSelectedIncidents(incidentId) {
  store.dispatch({
    type: appActionTypes.PUT_SELECTED_INCIDENTS,
    payload: {
      incidentId: incidentId
    }
  });
}

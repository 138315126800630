import { all, put, takeEvery } from "@redux-saga/core/effects";
import {
  confirmOTPForNintoPatientConnection,
  findPatientWithHealthIdOrPhoneNumber,
  // sendOTPForNintoPatientConnection,
  sendSubscriptionRequestToPatient,
  sendConsentRequest
} from "../../services/api";
import {
  isPatientSubscribed,
  followPatient,
  unFollowPatient,
  unPinPatient,
  isConsentRequestPresent
} from "../../services/database";
import { throwError } from "../../services/error";
import { isValidArray, isValidObject } from "../../services/validators";
import { setErrorStatus, setSuccessStatus } from "../status/actions";
import store from "../store/store";
import {
  checkConsentRequestPresent,
  isSubscriptionRequestPresent,
  sendSubscriptionRequest
} from "./actions";

export const abdmPatientsActionTypes = {
  FIND_PATIENT: "FIND_PATIENT",
  SEND_SUBSCRIPTION_REQUEST: "SEND_SUBSCRIPTION_REQUEST",
  IS_SUBSCRIPTION_REQUEST_PRESENT: "IS_SUBSCRIPTION_REQUEST_PRESENT",
  CHECK_IF_PATIENT_CONNECTED_TO_ABDM: "CHECK_IF_PATIENT_CONNECTED_TO_ABDM",
  CHECK_IF_PATIENT_CONNECTED_TO_NINTO: "CHECK_IF_PATIENT_CONNECTED_TO_NINTO",
  SEND_CONSENT_REQUEST: "SEND_CONSENT_REQUEST",
  NINTO_ACCEPT_INVITE: "NINTO_ACCEPT_INVITE",
  REMOVE_ABDM_PATIENT_SEARCH_DATA: "REMOVE_ABDM_PATIENT_SEARCH_DATA",
  CHECK_CONSENT_REQUEST_PRESENT: "CHECK_CONSENT_REQUEST_PRESENT",
  GET_DATA_TRANSFER_DETAILS: "GET_DATA_TRANSFER_DETAILS",
  PUT_PINNED_PATIENTS: "PUT_PINNED_PATIENTS",
  PUT_FOLLOWED_PATIENTS: "PUT_FOLLOWED_PATIENTS",
  FOLLOW_PATIENT: "FOLLOW_PATIENT",
  UN_FOLLOW_PATIENT: "UN_FOLLOW_PATIENT",
  UN_PIN_PATIENT: "UN_PIN_PATIENT"
};

function* findPatientWorker(action) {
  try {
    yield setAbdmPatientsLoading(true);
    if (typeof action.payload.searchKey !== "string") {
      throw throwError("custom", "Please provide search key");
    }

    const currentClinicId = store.getState().clinics.currentClinic;
    if (action.payload.type !== "patientId") {
      yield put({
        type: "SET_ABDM_SEARCHED_PATIENT_SEARCH_KEY",
        payload: {
          searchKey: action.payload.searchKey
        }
      });
    }

    const findPatientWithHealthIdOrPhoneNumberResponse =
      yield findPatientWithHealthIdOrPhoneNumber(
        action.payload.searchKey.includes("@")
          ? { healthId: action.payload.searchKey }
          : action.payload.searchKey.includes("+91")
          ? { phoneNumber: action.payload.searchKey }
          : { patientId: action.payload.searchKey },
        currentClinicId,
        store.getState().auth.data.accessToken
      );

    if (findPatientWithHealthIdOrPhoneNumberResponse.success) {
      if (
        findPatientWithHealthIdOrPhoneNumberResponse.data.findResults.length > 0
      ) {
        let searchResults = {};

        findPatientWithHealthIdOrPhoneNumberResponse?.data?.findResults?.forEach(
          (element) => {
            searchResults[element?.patientId] = {
              ...element
            };
          }
        );

        yield put({
          type: "SET_ABDM_PATIENT_SEARCH_RESULTS",
          payload: {
            data: searchResults
          }
        });
      } else {
        setErrorStatus({
          code: "custom",
          message: "No user found"
        });
      }
    } else {
      throw throwError("custom", "Invalid Health Id");
    }
    yield setAbdmPatientsLoading(false);
  } catch (error) {
    yield setAbdmPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* sendConsentRequestWorker(action) {
  try {
    yield setAbdmPatientsLoading(true);
    const accessToken = store.getState().auth?.data?.accessToken;
    const clinic =
      store.getState().clinics?.data?.[store.getState().clinics.currentClinic];

    if (accessToken && action.payload.patientId && isValidObject(clinic)) {
      const data = {
        patientId: action.payload.patientId,
        requesterName: clinic.companyName,
        clinicId: clinic.connectionId,
        dateRange: {
          from: action.payload.fromDate
            ? action.payload.fromDate
            : new Date().toISOString(),
          to: new Date(
            new Date().getFullYear(),
            new Date().getMonth() + action.payload.expiryMonth,
            new Date().getDate()
          ).toISOString()
        }
      };

      const response = yield sendConsentRequest(accessToken, data);

      if (response.success === true) {
        setSuccessStatus("Consent-request sent successfully");
        checkConsentRequestPresent(
          action.payload.healthId,
          action.payload.patientId
        );
      }
    }
    yield setAbdmPatientsLoading(false);
  } catch (error) {
    yield setAbdmPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* nintoAcceptInviteWorker(action) {
  try {
    yield setAbdmPatientsLoading(true);
    const userType = "patient";
    const otpType = "Connection Request";
    const userPhoneNumber =
      store.getState().abdmPatients?.selectedPatient?.phoneNumber;
    const accessToken = store.getState().auth.data.accessToken;
    if (
      action.payload.otp &&
      userType &&
      userPhoneNumber &&
      action.payload.requestId &&
      otpType
    ) {
      const response = yield confirmOTPForNintoPatientConnection(
        accessToken,
        action.payload.otp,
        userType,
        userPhoneNumber,
        otpType,
        action.payload.requestId
      );
      if (response.success === true) {
        setSuccessStatus("Connection request accepted successfully");
      }
    }

    yield setAbdmPatientsLoading(false);
  } catch (error) {
    yield setAbdmPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* sendSubscriptionRequestWorker(action) {
  try {
    yield setAbdmPatientsLoading(true);

    const sendSubscriptionRequestData = {
      patientId: action.payload.patientId,
      clinicId: store.getState().clinics.currentClinic,
      period: {
        from: new Date().toISOString(),
        to: new Date(
          new Date().getFullYear(),
          new Date().getMonth() + action.payload.expiryMonth,
          new Date().getDate()
        ).toISOString()
      },
      purpose: {
        text: `${
          store.getState().clinics.data[store.getState().clinics.currentClinic]
            .companyName
        } wants to subscribe to your health Data`,
        code: "[CAREMGT, PATRQT]"
      }
    };

    const response = yield sendSubscriptionRequestToPatient(
      store.getState().auth.data.accessToken,
      sendSubscriptionRequestData
    );

    if (response.success === true) {
      setSuccessStatus(response.data.message);
      isSubscriptionRequestPresent(
        action.payload.healthId,
        action.payload.patientId
      );
    }
    yield setAbdmPatientsLoading(false);
  } catch (error) {
    yield setAbdmPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* isSubscriptionRequestPresentWorker(action) {
  try {
    const currentClinicId = store.getState().clinics.currentClinic;

    const response = yield isPatientSubscribed(
      action.payload.healthId,
      currentClinicId
    );
    if (!isValidArray(response)) {
      sendSubscriptionRequest(
        6,
        action.payload.healthId,
        action.payload.patientId
      );
    }
    yield put({
      type: "SET_SUBSCRIPTION_REQUEST_DATA",
      payload: {
        subscriptionRequest: {
          ...store.getState().abdmPatients.subscriptionRequest,
          [action.payload.patientId]: response
        }
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

function* clearPatientSearchData(action) {
  yield put({
    type: "CLEAR_ABDM_PATIENT_SEARCH_DATA"
  });
}

export function* isConsentRequestPresentWorker(action) {
  if (action.payload.loading) {
    yield setAbdmPatientsLoading(true);
  }
  try {
    const response = yield isConsentRequestPresent(
      action.payload.healthId,
      store.getState().clinics.currentClinic
    );
    if (typeof action.payload.patientId === "string") {
      yield put({
        type: "SET_CONSENT_REQUEST_DATA",
        payload: {
          consentRequest: {
            ...store.getState().abdmPatients.consentRequest,
            [action.payload.patientId]: response
          }
        }
      });
    }
    yield setAbdmPatientsLoading(false);
  } catch (error) {
    yield setAbdmPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* putPinnedPatientsWorker(action) {
  try {
    let pinnedPatients = {};

    if (isValidObject(action.payload.pinnedPatients)) {
      for (const documentId in action.payload.pinnedPatients) {
        const patientData = action.payload.pinnedPatients[documentId];

        pinnedPatients = {
          ...pinnedPatients,
          [patientData.patient.patientId]: patientData
        };
      }
      yield put({
        type: "SET_PINNED_PATIENTS",
        payload: {
          pinned: pinnedPatients
        }
      });
    } else {
      yield put({
        type: "SET_PINNED_PATIENTS",
        payload: {
          pinned: null
        }
      });
    }

    yield setAbdmPatientsLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setAbdmPatientsLoading(false);
  }
}

function* putFollowedPatientsWorker(action) {
  try {
    let followingPatients = {};

    if (isValidObject(action.payload.followedPatients)) {
      for (const documentId in action.payload.followedPatients) {
        const patientData = action.payload.followedPatients[documentId];

        followingPatients = {
          ...followingPatients,
          [patientData.patient.patientId]: patientData
        };
      }

      if (!isValidObject(followingPatients)) {
        throwError("custom", "Unable to set patients");
      }
      yield put({
        type: "SET_FOLLOWING_PATIENTS",
        payload: {
          following: {
            ...followingPatients
          }
        }
      });
    } else {
      yield put({
        type: "SET_FOLLOWING_PATIENTS",
        payload: {
          following: null
        }
      });
    }

    yield setAbdmPatientsLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setAbdmPatientsLoading(false);
  }
}

function* followPatientWorker(action) {
  try {
    yield setAbdmPatientsLoading(true);
    const currentClinic = store.getState().clinics.currentClinic;
    const doctorId = Object.keys(store.getState().profile.data)[0];

    let patients = {};
    store.getState().abdmPatients.following &&
      Object.keys(store.getState().abdmPatients.following).forEach(
        (patientId) => {
          const data = store.getState().abdmPatients.following[patientId];
          if (
            data.pinnedBy.clinicId === store.getState().clinics.currentClinic
          ) {
            patients = {
              ...patients,
              [data.patient.patientId]: data
            };
          }
        }
      );

    let usedIds = [];
    let successivePatient = [];

    if (!patients) {
      successivePatient = null;
    } else {
      Object.keys(patients).forEach((id) => {
        Object.values(patients).forEach((data) => {
          if (id === data.successivePatient) {
            usedIds.push(id);
          }
        });
      });

      successivePatient = Object.keys(patients).filter(
        (item) => !usedIds.includes(item)
      );
    }
    const pinnedPatientData = {
      doctorId: doctorId,
      patient: {
        patientId: action.payload.profileData.patientId,
        fullName: action.payload.profileData.fullName,
        healthId: action.payload.profileData.healthId,
        ...(action.payload.profileData.phoneNumber && {
          phoneNumber: action.payload.profileData.phoneNumber
        }),
        ...(action.payload.profileData.yob && {
          yob: action.payload.profileData.yob
        })
      },
      permanent: true,
      pinnedAt: +new Date(),
      pinnedBy: {
        clinicId: currentClinic,
        staffId: doctorId
      },
      successivePatient: isValidArray(successivePatient)
        ? successivePatient[0]
        : null
    };

    if (isValidObject(pinnedPatientData)) {
      yield followPatient(pinnedPatientData);
      isSubscriptionRequestPresent(
        action.payload.profileData.healthId,
        action.payload.profileData.patientId
      );
    }

    yield setAbdmPatientsLoading(false);
  } catch (error) {
    yield setAbdmPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* unFollowPatientWorker(action) {
  try {
    yield setAbdmPatientsLoading(true);
    // const currentClinic = store.getState().clinics.currentClinic;
    const doctorId = Object.keys(store.getState().profile.data)[0];

    const followedPatient = store.getState().abdmPatients.following;

    const updateId = Object.keys(followedPatient).find(
      (patientId) =>
        followedPatient[patientId].successivePatient ===
        action.payload.patientId
    );

    const updateData = {
      patientId: updateId,
      successivePatient:
        followedPatient[action.payload.patientId].successivePatient
    };

    unFollowPatient(doctorId, action.payload.patientId, updateData);

    yield setAbdmPatientsLoading(false);
  } catch (error) {
    yield setAbdmPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* unpinPatientWorker(action) {
  try {
    yield setAbdmPatientsLoading(true);
    // const currentClinic = store.getState().clinics.currentClinic;
    const doctorId = Object.keys(store.getState().profile.data)[0];

    const pinnedPatient = store.getState().abdmPatients.pinned;

    const updateId = Object.keys(pinnedPatient).find(
      (patientId) =>
        pinnedPatient[patientId].successivePatient === action.payload.patientId
    );

    const updateData = {
      patientId: updateId,
      successivePatient:
        pinnedPatient[action.payload.patientId].successivePatient
    };

    unPinPatient(doctorId, action.payload.patientId, updateData);

    yield setAbdmPatientsLoading(false);
  } catch (error) {
    yield setAbdmPatientsLoading(false);
  }
}

export default function* abdmPatientsWatcher() {
  yield all([
    takeEvery("FIND_PATIENT", findPatientWorker),
    takeEvery("SEND_SUBSCRIPTION_REQUEST", sendSubscriptionRequestWorker),
    takeEvery("SEND_CONSENT_REQUEST", sendConsentRequestWorker),
    takeEvery("NINTO_ACCEPT_INVITE", nintoAcceptInviteWorker),
    takeEvery(
      "IS_SUBSCRIPTION_REQUEST_PRESENT",
      isSubscriptionRequestPresentWorker
    ),
    takeEvery("REMOVE_ABDM_PATIENT_SEARCH_DATA", clearPatientSearchData),
    takeEvery("CHECK_CONSENT_REQUEST_PRESENT", isConsentRequestPresentWorker),
    takeEvery("PUT_PINNED_PATIENTS", putPinnedPatientsWorker),
    takeEvery("PUT_FOLLOWED_PATIENTS", putFollowedPatientsWorker),
    takeEvery("FOLLOW_PATIENT", followPatientWorker),
    takeEvery("UN_FOLLOW_PATIENT", unFollowPatientWorker),
    takeEvery("UN_PIN_PATIENT", unpinPatientWorker)
  ]);
}

function* setAbdmPatientsLoading(loadingState) {
  yield put({
    type: "SET_ABDM_PATIENTS_LOADING",
    payload: {
      loading: loadingState
    }
  });
}
